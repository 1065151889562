require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels");
require("jquery");
require("moment");
require("moment/locale/de");
require("moment/locale/it");
require("moment/locale/es");
require("moment/locale/fr");
require("@popperjs/core")
require('bootstrap');
require("i18n-iso-countries");
require("sortablejs");
require("cookies-js");

countries.registerLocale(require("../src/javascripts/i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("../src/javascripts/i18n-iso-countries/langs/es.json"));
countries.registerLocale(require("../src/javascripts/i18n-iso-countries/langs/it.json"));
countries.registerLocale(require("../src/javascripts/i18n-iso-countries/langs/de.json"));
countries.registerLocale(require("../src/javascripts/i18n-iso-countries/langs/fr.json"));

import * as bootstrap from 'bootstrap';

import Rails from '@rails/ujs';
import Cookies from 'cookies-js';
import moment from 'moment';
import bootbox from '../src/javascripts/bootbox.js';
import jquery from 'jquery';
import countries from 'i18n-iso-countries';
import Sortable from 'sortablejs';

import "../src/javascripts/i18n.js.erb";

window.bootstrap = bootstrap;

import '../src/javascripts/bootstrap-table.js';
import '../src/javascripts//bootstrap-table-en-US.min';
import '../src/javascripts/bootstrap-table-de-DE.min';
import '../src/javascripts/bootstrap-table-it-IT.min';
import '../src/javascripts/bootstrap-table-es-ES.min';
import '../src/javascripts/bootstrap-table-fr-FR.min';
require('../src/javascripts/bootstrap-select.min.js');

Cookies.defaults = {
    path: '/',
    secure: true
};

window.Rails = Rails;
window.Cookies = Cookies;
window.moment = moment;
window.bootbox = bootbox;
window.jquery = jquery;
window.$ = jquery;
window.jQuery = jquery;
window.countries = countries;
window.Sortable = Sortable;

moment.updateLocale('en', {
  week : {
    dow : 1
  },
  longDateFormat : {
    L: "DD/MM/YYYY",
    l: "D/M/YYYY",
  }
});

window.tempusDominus = require("@eonasdan/tempus-dominus");
require("../src/javascripts/moment_parse.js");
require("../src/javascripts/fa-five.js");
window.tempusDominus.extend(window.tempusDominus.plugins.moment_parse, 'yyyy-MM-DD');
window.tempusDominus.extend(window.tempusDominus.plugins.fa_five);

const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

import * as business from '../src/javascripts/business.js.erb';
window.business = business;

import "controllers"
