import { Controller } from "stimulus"
import { DirectUpload } from "@rails/activestorage"
import { TempusDominus } from "@eonasdan/tempus-dominus"

export default class extends Controller {

  static targets = [ "filter", "parties", "attachment_uploading", "attachments_counter", "td_price", "td_price_with_vat", "td_total", "td_total_with_vat", "td_discount", "positions", "position_number", "toggleVAT", "vat_select_template", "retention", "total_vat_sum", "total_eqs_sum", "position_total", "position_total_with_vat", "position_vat", "total", "total_vat", "total_with_vat", "total_retention", "total_eqs", "vats_summary", "equivalence_surcharge" ]

  static values = {
    currency: String,
    currencyFormat: String,
    currencyAbbr: String,
    vatLabel: String
  }

  initialize() {
    if(this.element.getAttribute("data-locale")) {
      this.locale = this.element.getAttribute("data-locale");
    }
    if(this.element.getAttribute("data-currency")) {
      this.currency = this.element.getAttribute("data-currency");
    }
    if(this.element.getAttribute("data-page")) {
      this.page = this.element.getAttribute("data-page");
    }

    Number.prototype.round = function(places) {
      return +(Math.round(this + "e+" + places)  + "e-" + places);
    }
  }

  connect() {
    if(this.page == 'index') {
      this.initData();
      this.initTableFunctions();
    }
    if(this.page == 'new' || this.page == 'edit') {
      this.initItemSelectDialog();
      this.initInventoryItemSelectDialog();
      this.enablePositionSorting();

      if(this.page == 'new') {
        this.appendPosition()
      }

      if(this.page == 'edit') {
        this.loadContract()
      }
    }

    this.initDatePickers();

    $('#loading').fadeOut(200);
  }

  initDatePickers() {
    let that = this;
    document.querySelectorAll("input.datetimepicker-input").forEach((inp, i) => {
      let picker = inp.closest('.input-group');
      if(picker) {
        new TempusDominus(picker, {
          localization: {
            locale: this.locale
          },
          display: {
            components: {
              date: true,
              decades: true,
              month: true,
              year: true,
              hours: false,
              seconds: false,
              minutes: false
            }
          }
        });
      }
    });
  }

  enablePositionSorting() {
    let that = this;
    new Sortable(positions, {
        handle: '.handle',
        animation: 150,
        onEnd: () => {
          that.refreshNumbering();
        }
    });
  }

  currenciesAbbr(currency) {
    if(this.currencyAbbrValue == 'iso4217') {
      return currency.toUpperCase();
    } else {
      return I18n.t(`currencies.abbr.${currency}`);
    }
  }

  currenciesFormat(value, currency) {
    if(this.currencyFormatValue == '%u %n') {
      return `${this.currenciesAbbr(currency)} ${value}`
    } else {
      return `${value} ${this.currenciesAbbr(currency)}`
    }
  }

  filterContacts() {
    Cookies.set('filter_contacts', this.filterTarget.value.toLowerCase());
  }

  clearFilter() {
    Cookies.expire('filter_contacts');

    $("#contracts").bootstrapTable('resetSearch', '');
    this.filterTarget.focus();
    this.filterTarget.blur();
  }

  loadContract() {
    let that = this;
    let positions = document.querySelector('#positions');
    positions.rows.forEach((row, i) => {
      let discount = row.querySelector('#position_discount').value;
      if(discount != '') {
        let inp_price = row.querySelector('#position_price');
        let inp_price_vat = row.querySelector('#position_price_with_vat');
        let inp_discount_price = row.querySelector('#position_price_with_discount');
        let inp_discount_price_vat = row.querySelector('#position_price_with_discount_vat');
        let vat = parseFloat(row.querySelector('#position_vat').value);

        inp_price.classList.add('strikethrough');
        inp_price.classList.add('form-control-sm');
        inp_price.setAttribute('readonly', true);

        inp_price_vat.classList.add('strikethrough');
        inp_price_vat.classList.add('form-control-sm');
        inp_price_vat.setAttribute('readonly', true);

        inp_discount_price.classList.remove('d-none');

        let price_with_vat = parseFloat(inp_discount_price.value)*(1+(vat/100))
        inp_discount_price_vat.value = price_with_vat.toFixed(2);
        inp_discount_price_vat.classList.remove('d-none');
      }
    });
    this.appendPosition()
  }

  toggleRetention() {
    setTimeout(() => {
      let inp_retention = document.querySelector("#contract_total_retention");
      if(this.retentionTarget.checked) {
        document.querySelector("#total_retention").classList.remove('d-none');
      } else {
        document.querySelector("#total_retention").classList.add('d-none');
      }
      this.updateContractTotal();
    }, 100);
  }

  toggleEquivalenceSurcharge() {
    setTimeout(() => {
      let eqs = document.querySelectorAll(".equivalence_surcharge");
      if(!this.equivalence_surchargeTarget.checked) {
        for(let i = 0; i < eqs.length; i++) {
          eqs[i].classList.remove("d-flex");
          eqs[i].classList.add("d-none");
        }
      }

      this.updateContractTotal();
    }, 100);
  }

  toggleNote() {
    let td = event.target.closest('td');
    let note_div = td.querySelector('div.note');
    note_div.classList.toggle('d-none')
    let txt_note = td.querySelector('#position_note');
    txt_note.textContent = ''
  }

  toggleCommission() {
    let div = event.target.closest('div.col').querySelector("[data-target='commission']");
    if(event.target.value == 'customer') {
      div.classList.add('d-none');
    } else {
      div.classList.remove('d-none');
    }
  }

  toggleWithVAT() {
    setTimeout((event) => {
      if(this.toggleVATTarget.checked) {
        this.td_priceTargets.forEach((td, i) => {
          td.classList.add('d-none');
        });
        this.td_price_with_vatTargets.forEach((td, i) => {
          td.classList.remove('d-none')
        });
        this.td_totalTargets.forEach((td, i) => {
          td.classList.add('d-none');
        });
        this.td_total_with_vatTargets.forEach((td, i) => {
          td.classList.remove('d-none')
        });
      } else {
        this.td_price_with_vatTargets.forEach((td, i) => {
          td.classList.add('d-none')
        });
        this.td_priceTargets.forEach((td, i) => {
          td.classList.remove('d-none');
        });
        this.td_total_with_vatTargets.forEach((td, i) => {
          td.classList.add('d-none')
        });
        this.td_totalTargets.forEach((td, i) => {
          td.classList.remove('d-none');
        });
      }
    }, 100)
  }

  toggleDateEnd() {
    let parent = event.target.closest(".d-flex");
    let div = parent.querySelector(".input-group");
    if(event.target.checked) {
      div.querySelector('input').value = I18n.l("date.formats.default", new Date());
      div.querySelector('input').removeAttribute('disabled');
      div.classList.remove('no-pointer-events');
    } else {
      div.classList.add('no-pointer-events');
      div.querySelector('input').setAttribute('disabled', true);
      div.querySelector('input').value = null;
    }
  }

  selectContact() {
    this.targetBtn = event.target;
    I18n.locale = this.locale;
    let that = this;

    let html = `
      <div class="container p-0">
        <input type="hidden" id="contact_uuid">
        <input type="text" id="contact_filter" class="form-control form-control-sm border-0 border-bottom rounded-0 outline-0 mb-3" placeholder="Filter" autocomplete="off">
        <table class="table" data-caching="false">
          <thead class="small">
            <tr>
              <th scope="col" data-field="customer_number" data-sortable="true">
                ${I18n.t('contact.customer_number')}
              </th>
              <th scope="col" data-field="firstname" data-sortable="true">
                ${I18n.t('contact.firstname')}
              </th>
              <th scope="col" data-field="lastname" data-sortable="true">
                ${I18n.t('contact.lastname')}
              </th>
              <th scope="col" data-field="company" data-sortable="true">
                ${I18n.t('contact.company')}
              </th>
            </tr>
          </thead>
          <tbody class="smaller pointer-hand">
          </tbody>
        </table>
      </div>
    `

    bootbox.dialog({
      title: I18n.t('contract.contact_select'),
      message: html,
      size: 'large',
      onEscape: true,
      buttons: {
        cancel: {
          label: `<i class="fas fa-times"></i> ${I18n.t('btn.cancel')}`,
          className: "btn-outline-secondary",
          callback: function() {
            that.targetBtn = null;
          }
        }
      },
      onShown: (event) => {
        event.target.querySelector('input.form-control').focus();
        let table = event.target.querySelector('table');
        $(table).bootstrapTable({
          url: `/${that.locale}/contract/contacts`,
          locale: that.locale,
          minHeight: 400,
          height: 500,
          stickyHeader: true,
          classes: 'table table-hover',
          undefinedText: '',
          uniqueId: 'uuid',
          searchSelector: '#contact_filter',
          search: true,
          searchTimeOut: 250,
          loadingFontSize: '1rem',
          onClickRow: (row, el, field) => {
            let inp = event.target.querySelector('input#contact_uuid');
            inp.value = row.uuid;
            that.contactSelected(row.uuid);
            bootbox.hideAll();
          }
        });
      }
    });
  }

  contactSelected(uuid) {
    let that = this;
    let inp = this.targetBtn.parentNode.querySelector("input#party_contact");
    inp.value = uuid;
    Rails.ajax({
      url: `/${this.locale}/contact/${uuid}.json`,
      type: "get",
      data: "",
      success: function(data) {
        let txtarea = that.targetBtn.parentNode.querySelector('textarea');

        if(data.company) {
          that.targetBtn.innerHTML = `<i class="fas fa-address-book"></i> ${data.company}`;
        } else if(data.firstname || data.lastname) {
          that.targetBtn.innerHTML = `<i class="fas fa-address-book"></i> ${[data.firstname, data.lastname].join(' ')}`
        }

        if(data.addresses && data.addresses.length > 0) {
          let addr = '';
          let addr_name = ''
          if(`${data.company}`.trim() != '') {
            addr_name = data.company;
          } else {
            if(data.firstname != '' || data.lastname != '') {
              addr_name = (`${data.firstname} ${data.lastname}`).trim();
            }
          }

          if(data.addresses.length > 0) {
            if((data.addresses[0].addressline1 && data.addresses[0].addressline1 != '') || (data.addresses[0].addressline2 && data.addresses[0].addressline2 != '')) {
              if(data.addresses[0].addressline1 && data.addresses[0].addressline1 != '') {
                addr += data.addresses[0].addressline1+"\n";
              }
              if(data.addresses[0].addressline2 && data.addresses[0].addressline2 != '') {
                addr += data.addresses[0].addressline2+"\n";
              }
            } else {
              addr += addr_name+"\n";
            }

            addr += data.addresses[0].street+"\n";

            if(data.addresses[0].country && data.addresses[0].country != '') {
              addr += `${data.addresses[0].country}-${data.addresses[0].zip} ${data.addresses[0].location}`;
            } else {
              addr += `${data.addresses[0].zip} ${data.addresses[0].location}`;
            }
          }

          if(data.taxid != '' && data.taxid != null) {
            addr += `\n\n${that.vatLabelValue}: ${data.taxid}`;
          }
          if(data.data && data.data.fiscal_code && data.data.fiscal_code != '') {
            addr += `\n${I18n.t("contact.fiscal_code_abbr")}: ${data.data.fiscal_code}`;
          }
          if(data.data && data.data.unique_code && data.data.unique_code != '') {
            addr += `\n${I18n.t("contact.unique_code")}: ${data.data.unique_code}`;
          }

          if(data.emails.length > 0) {
            data.emails.forEach((email) => {
              if(email.email_type == 'cem') {
                addr += `\n${I18n.t("contact.cem")}: ${email.email_address}`;

                data_cem.value = email.email_address;
              }
            })
          }
          txtarea.value = addr;
        }

        this.targetBtn = null;
      },
      error: function(data) {}
    });
  }

  addParty() {
    let div = event.target.closest("div.col");

    div.innerHTML = `
      <label for="party_role">
        ${I18n.t('contract.role')}
      </label>
      <div class="d-flex justify-content-between">
        <select id="party_role" name="parties[][role]" title="${I18n.t('contract.role')}" class="form-select role mb-3" data-size="10" data-contract-target="role" data-action="change->contracts#toggleCommission">
          <option value="customer" selected>${I18n.t('contract.roles.customer')}</option>
          <option value="distributor">${I18n.t('contract.roles.distributor')}</option>
          <option value="intermediary">${I18n.t('contract.roles.intermediary')}</option>
        </select>
        <button type="button" class="btn btn-danger btn-sm m-auto ms-2 mt-1" data-action="click->contracts#removeParty">
          <i class="fas fa-trash"></i>
        </button>
      </div>

      <label for="party_contact">
        ${I18n.t('contact.contact')}
      </label>
      <input type="hidden" id="party_contact"  name="parties[][contact]">
      <button type="button" class="btn btn-outline-secondary rounded-1 text-start mb-3" data-action="click->contracts#selectContact">
        <i class="fas fa-address-book"></i>
        ${I18n.t('contract.contact_select')}
      </button>
      <label for="party_contact">
        ${I18n.t('contact.address')}
      </label>
      <textarea id="party_address" name="parties[][address]" class="form-control mb-3" rows="6" data-contract-target="address" required></textarea>
      <div class="row mb-3 d-none" data-target="commission">
        <label for="party_commission" class="col-sm-4 col-form-label smaller ps-3">
          ${I18n.t('contract.commission')}
        </label>
        <div class="col-sm-5">
          <input type="number" id="party_commission" name="parties[][commission]" class="form-control text-end">
        </div>
        <div class="col-sm-3">
          <select id="party_commission_type" name="parties[][commission_type]" title="<%= t('contract.role') %>" class="form-select mb-3" data-size="10" data-contract-target="role">
            <option value="fix" selected>
              ${I18n.t(`currencies.abbr.${this.currency}`)}
            </option>
            <option value="percentage">%</option>
          </select>
        </div>
      </div>
    `;

    let divAppend = document.createElement('div');
    divAppend.classList.add('col', 'col-sm-3', 'mb-3', 'd-flex', 'flex-column');
    divAppend.innerHTML = `
      <div class="mt-1">
        <button type="button" data-action="click->contracts#addParty" class="btn btn-secondary m-auto mt-3 ms-0">
          <i class="fas fa-user-plus"></i>
          ${I18n.t('contract.party_add')}
        </button>
      </div>
    `
    this.partiesTarget.appendChild(divAppend)
  }

  removeParty() {
    let div = event.target.closest('div.col');
    div.remove();
  }

  insertPosition() {
    let row = event.target.closest('tr');
    if(row.rowIndex == this.positionsTarget.rows.length-1) {
      this.appendPosition()
    }
  }

  appendPosition(data) {
    if(data) {
      let tr = this.positionsTarget.rows[this.positionsTarget.rows.length-1];
      if(tr.querySelector("#position_name").value == '' && tr.querySelector("#position_quantity").value == '' && tr.querySelector("#position_total").value == '') {
        this.positionsTarget.deleteRow(this.positionsTarget.rows.length-1);
      }
    }

    let that = this;
    var row = this.positionsTarget.insertRow();
    row.innerHTML = `
      <input type="hidden" id="position_uuid" name="positions[][uuid]" value="">
      <input type="hidden" id="position_item" name="positions[][item]" value="${data ? data.uuid : ''}">
      <input type="hidden" id="position_inventory_item" name="positions[][inventory_item]" value="${data ? data.item : ''}">
      <input type="hidden" id="position_billing" name="positions[][billing]" value="${(data && data.data) ? data.data.billing : ''}">
      <input type="hidden" id="position_billing_interval" name="positions[][billing_interval]" value="${(data && data.data) ? data.data.billing_interval : ''}">
    `
    var td1 = row.insertCell();
    td1.classList.add("pe-2");
    td1.innerHTML = `<div class="d-flex align-items-center pt-1">
      <div class="handle pointer-move-up-down me-2">
        <i class="fas fa-grip-vertical fa-lg"></i>
      </div>
      <span class="badge bg-secondary" data-contracts-target="position_number">
        ${this.positionsTarget.rows.length-1}
      </span>
    </div>`;

    var td2 = row.insertCell();
    td2.innerHTML = `<div class="d-flex justify-content-between align-items-center">
                      <span class="item-info text-secondary d-none">
                        <i class="fa-solid fa-circle-info fa-fw"></i>
                      </span>
                      <input type="text" class="form-control position flex-fill" id="position_name" name="positions[][label]" value="${data ? data.i18n_name : ''}" data-action="input->contracts#insertPosition" autocomplete="off">
                      <button type="button" class="btn btn-sm btn-link text-secondary ms-1" data-action="click->contracts#toggleNote">
                        <i class="fa-solid fa-align-left"></i>
                      </button>
                    </div>
                    <div class="d-none note">
                      <textarea id="position_note" name="positions[][note]" rows="2" class="form-control form-controll-sm smaller mt-2 mb-2" placeholder="${I18n.t('item.note')}" style="width: calc(100% - 30px)"></textarea>
                    </div>`

    let inp = td2.querySelector("#position_name");

    var tdIN = row.insertCell();
    tdIN.classList.add("text-end")
    tdIN.innerHTML = `<input type="text" class="form-control position" id="position_inventorynumber" name="positions[][inventorynumber]" value="${(data && data.inventorynumber) ? data.inventorynumber : ''}">`

    var tdSN = row.insertCell();
    tdSN.classList.add("text-end")
    tdSN.innerHTML = `<input type="text" class="form-control position" id="position_serialnumber" name="positions[][serialnumber]" value="${(data && data.serialnumber) ? data.serialnumber : ''}">`

    var td3 = row.insertCell();
    td3.classList.add("text-end")
    td3.innerHTML = `<input type="number" class="form-control position text-end" step="0.01" id="position_quantity" name="positions[][quantity]" data-action="input->contracts#updatePositionTotal" value="${(data && data.quantity) ? data.quantity : ''}">`

    var td4 = row.insertCell();
    td4.classList.add("text-end")
    if(this.toggleVATTarget.checked) {
      td4.classList.add("d-none")
    }
    td4.setAttribute("data-contracts-target", "td_price")
    td4.innerHTML = `
      <input type="number" class="form-control position text-end" step="0.01" id="position_price" name="positions[][price]" data-action="input->contracts#calcPriceWithVAT" value="${(data && data.price) ? data.price.toFixed(2) : ''}">
      <input type="hidden" id="position_discount" name="positions[][discount]">
      <input type="hidden" id="position_discount_type" name="positions[][discount_type]">
      <input type="number" class="form-control position text-end d-none text-danger" id="position_price_with_discount" name="positions[][price_with_discount]" readonly  data-action="click->contracts#editPrice">
    `

    var td5 = row.insertCell();
    td5.classList.add("text-end")
    if(!this.toggleVATTarget.checked) {
      td5.classList.add("d-none")
    }
    td5.setAttribute("data-contracts-target", "td_price_with_vat")
    td5.innerHTML = `
      <input type="number" class="form-control position text-end" step="0.01" id="position_price_with_vat" name="positions[][price_with_vat]" data-action="input->contracts#calcPriceWithoutVAT" value="${(data && data.price_with_vat) ? data.price_with_vat.toFixed(2) : ''}">
      <input type="number" class="form-control position text-end d-none text-danger" id="position_price_with_discount_vat" name="positions[][price_with_discount_vat]" readonly>
    `

    var tdP = row.insertCell();
    tdP.classList.add("text-end")
    tdP.innerHTML = `<button type="button" class="btn btn-sm btn-link text-secondary ps-0 pe-1" data-action="click->contracts#editPrice">
      <i class="fa-solid fa-tag"></i>
    </button>`

    var td6 = row.insertCell();
    td6.classList.add("text-end")
    if(this.toggleVATTarget.checked) {
      td6.classList.add("d-none")
    }
    td6.setAttribute("data-contracts-target", "td_total")
    td6.innerHTML = `<input type="number" class="form-control position text-end" step="0.01" id="position_total" name="positions[][total]" data-contracts-target="position_total" value="${(data && data.total) ? data.total.toFixed(2) : ''}">`

    var td7 = row.insertCell();
    td7.classList.add("text-end")
    if(!this.toggleVATTarget.checked) {
      td7.classList.add("d-none")
    }
    td7.setAttribute("data-contracts-target", "td_total_with_vat")
    td7.innerHTML = `<input type="number" class="form-control position text-end" step="0.01" id="position_total_with_vat" name="positions[][total_with_vat]" data-contracts-target="position_total_with_vat" value="${(data && data.total_with_vat) ? data.total_with_vat.toFixed(2) : ''}">`

    var td8 = row.insertCell();
    td8.innerHTML = this.vat_select_templateTarget.innerHTML;
    let sel = td8.querySelector('select');
    sel.setAttribute('data-contracts-target', 'position_vat');

    var tdB = row.insertCell();
    tdB.classList.add("text-center");
    if(data && data.data && data.data.billing && data.data.billing == 'recurrent') {
      tdB.innerHTML = `<button type="button" class="btn btn-sm btn-link text-dark" data-action="click->contracts#setPositionBilling"><i class="fa-solid fa-rotate-right"></i></button>`
    } else {
      tdB.innerHTML = `<button type="button" class="btn btn-sm btn-link text-dark" data-action="click->contracts#setPositionBilling"><i class="fa-solid fa-circle-1"></i></button>`
    }

    var td9 = row.insertCell();
    td9.classList.add("text-end")
    td9.innerHTML = `<button type="button" class="btn btn-sm btn-link text-dark" data-action="click->contracts#removePosition"><i class="fa-solid fa-trash"></i></button>`

    if(data) {
      let icon = td2.querySelector(".item-info");
      icon.setAttribute('title', data.i18n_name)
      icon.classList.remove('d-none')
      new bootstrap.Tooltip(icon)

      this.appendPosition()
    }
  }

  removePosition() {
    let row = event.target.closest('tr');
    if(row) {
      row.remove()
    }
    if(this.positionsTarget.rows.length < 2) {
      this.appendPosition()
    }
    this.refreshNumbering()
    this.updateContractTotal()
  }

  setPositionBilling() {
    window.toggleBillingSettings = function() {
      if(event.target.id == 'once') {
        event.target.parentNode.querySelector('fieldset').setAttribute('disabled', true);
      } else {
        event.target.parentNode.querySelector('fieldset').removeAttribute('disabled');
      }
    }

    let row = event.target.closest('tr');
    let inp_billing = row.querySelector("input#position_billing");
    let inp_interval = row.querySelector("input#position_billing_interval");
    let inp_label = row.querySelector("input#position_name");
    let btn_icon = row.querySelector("td.text-center button");
    let interval = {};
    if(inp_interval.value != '') {
      interval = JSON.parse(inp_interval.value);
    }
    let html = `
      <p>${inp_label.value}</p>
      <input type="radio" class="btn-check" name="recurrency" id="once" autocomplete="off" value="once" ${inp_billing.value != 'recurrent' ? 'checked' : ''} onclick="toggleBillingSettings()">
      <label class="btn btn-outline-secondary" for="once">${I18n.t('contract.billing_once')}</label>

      <input type="radio" class="btn-check" name="recurrency" id="recurrent" autocomplete="off" value="recurrent" ${inp_billing.value == 'recurrent' ? 'checked' : ''} onclick="toggleBillingSettings()">
      <label class="btn btn-outline-secondary" for="recurrent">${I18n.t('contract.billing_recurrent')}</label>

      <div class="card mt-3">
        <div class="card-body">
          <fieldset ${inp_billing.value != 'recurrent' ? 'disabled' : ''}>
          <label for="price" class="form-label">${I18n.t('contract.billing_interval')}</label>
          <div class="d-flex">
            <div class="">
              <input type="text" class="form-control-plaintext" value="${I18n.t('contract.billing_every')}:" style="width:80px;">
            </div>

            <div class="mx-2">
              <input type="number" class="form-control" id="recurrency" value="${interval.every ? interval.every : '1'}" style="width:80px;">
            </div>

            <div class="">
              <select class="form-select" id="recurrency_unit">
                <option value="days" ${(interval.unit && interval.unit == 'days') ? 'selected' : ''}>${I18n.t('date.days')}</option>
                <option value="months" ${(interval.unit && interval.unit == 'months') ? 'selected' : ''}>${I18n.t('date.months')}</option>
                <option value="years" ${(interval.unit && interval.unit == 'years') ? 'selected' : ''}>${I18n.t('date.years')}</option>
              </select>
            </div>
          </div>
          </fieldset>
        </div>
      </div>
    `

    var dialog = bootbox.dialog({
      message: html,
      onEscape: true,
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        apply: {
          label: I18n.t('btn.apply'),
          className: "btn-outline-success",
          callback: function() {
            inp_billing.value = dialog[0].querySelector("input[type=radio]:checked").value
            if(inp_billing.value == 'recurrent') {
               inp_interval.value = JSON.stringify({
                 every: dialog[0].querySelector("input#recurrency").value,
                 unit: dialog[0].querySelector("select#recurrency_unit").value
               })
               btn_icon.innerHTML = `<i class="fa-solid fa-rotate-right"></i>`
            } else {
              inp_interval.value = '';
              btn_icon.innerHTML = `<i class="fa-solid fa-circle-1"></i>`
            }
          }
        }
      }
    });
  }

  refreshNumbering() {
    this.position_numberTargets.forEach((lbl, i) => {
      lbl.innerText = i+1;
    });
  }

  selectAttachment() {
    var input = document.querySelector("#filepicker");
    input.click();
  }

  addItem() {
    var table = $('#itemSelect').bootstrapTable({});

    this.itemSelectDialog.show();

    let dialog = document.querySelector('#itemSelectDialog');

    dialog.addEventListener('shown.bs.modal', function (event) {
      event.target.querySelector(".search-input").focus();
    })
  }

  addInventoryItem() {
    var table = $('#inventoryItemSelect').bootstrapTable({});

    this.inventoryItemSelectDialog.show();

    let dialog = document.querySelector('#inventoryItemSelectDialog');

    dialog.addEventListener('shown.bs.modal', function (event) {
      event.target.querySelector(".search-input").focus();
    })
  }

  addSelectedItems(items) {
    let that = this;
    for(let i = 0; i < items.length; i++) {
      this.appendPosition(items[i])
    }
    this.updateContractTotal()
  }

  addSelectedInventoryItems(items) {
    for(let i = 0; i < items.length; i++) {
      items[i].i18n_name = items[i].name
      items[i].price = items[i].value_current
      items[i].price_with_vat = items[i].value_current
      items[i].quantity = 1
      items[i].total = items[i].price
      items[i].total_with_vat = items[i].price
      this.appendPosition(items[i])
    }
    this.updateContractTotal()
  }

  editPrice() {
    let that = this;
    I18n.locale = this.locale
    let tr = event.target.closest('tr')
    let price = tr.querySelector('#position_price').value;
    let discount = tr.querySelector('#position_discount').value;
    let discount_type = tr.querySelector('#position_discount_type').value;
    let price_with_discount = tr.querySelector('#position_price_with_discount').value;
    if(price == '') {
      tr.querySelector('#position_price').value = "0.00";
      price = "0.00";
    } else {
      price = parseFloat(price).toFixed(2)
      tr.querySelector('#position_price').value = price;
    }
    let price_discounted = price;
    if(price_with_discount != '') {
      price_discounted = price_with_discount;
    }
    let html = `
      <div class="container" data-controller="documents">
        <div class="mb-3">
          <label for="price" class="form-label">${I18n.t('item.price')}</label>
          <div class="input-group" style="max-width: 210px">
            <input type="number" class="form-control text-end" id="discount_price" value="${price}" data-documents-target="discount_price" data-action="input->documents#calcPriceWithDiscount">
            <span class="input-group-text bg-white" style="width: 95px;">
              ${this.currenciesAbbr(this.currencyValue)}
            </span>
          </div>
        </div>
        <div class="mb-3">
          <label for="price" class="form-label">${I18n.t('item.discount')}</label>
          <div class="input-group" style="max-width: 210px">
            <input type="number" class="form-control text-end" id="discount" value="${discount}" data-documents-target="discount_discount" data-action="input->documents#calcPriceWithDiscount">
            <select id="discount_type" class="form-select" data-documents-target="discount_type" data-action="input->documents#calcPriceWithDiscount" style="max-width: 95px;">
              <option value="fix">
                ${this.currenciesAbbr(this.currencyValue)}
              </option>
              <option value="percentage" ${discount != '' ? 'selected' : ''}>
                %
              </option>
            </select>
          </div>
        </div>
        <div class="mb-3">
          <label for="price" class="form-label">${I18n.t('item.price_with_discount')}</label>
          <div class="input-group" style="max-width: 210px">
            <input type="number" class="form-control text-end" id="price_with_discount" value="${price_discounted}" data-documents-target="discount_price_discounted" data-action="input->documents#calcPriceWithoutDiscount">
            <span class="input-group-text bg-white" style="width: 95px;">
              ${this.currenciesAbbr(this.currencyValue)}
            </span>
          </div>
        </div>
      </div>
    `
    var dialog = bootbox.dialog({
      message: html,
      onEscape: true,
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        apply: {
          label: I18n.t('btn.apply'),
          className: "btn-outline-success",
          callback: function() {
            let box_price = document.querySelector("#discount_price");
            let box_discount_price = document.querySelector("#price_with_discount");
            let box_discount = document.querySelector("#discount");
            let box_discount_type = document.querySelector("#discount_type");

            let inp_price = tr.querySelector('#position_price');
            let inp_price_vat = tr.querySelector('#position_price_with_vat');
            let inp_discount_price = tr.querySelector('#position_price_with_discount');
            let inp_discount_price_vat = tr.querySelector('#position_price_with_discount_vat');
            let inp_discount = tr.querySelector('#position_discount');
            let inp_discount_type = tr.querySelector('#position_discount_type');

            let vat = parseFloat(tr.querySelector('#position_vat').value);

            if(box_discount.value != '') {
              inp_price.classList.add('strikethrough');
              inp_price.classList.add('form-control-sm');
              inp_price.setAttribute('readonly', true);
              inp_price.value = parseFloat(box_price.value).toFixed(2);

              inp_price_vat.classList.add('strikethrough');
              inp_price_vat.classList.add('form-control-sm');
              inp_price_vat.setAttribute('readonly', true);

              inp_discount_price.value = box_discount_price.value;
              inp_discount_price.classList.remove('d-none');

              let price_with_vat = parseFloat(box_discount_price.value)*(1+(vat/100))
              inp_discount_price_vat.value = price_with_vat.toFixed(2);
              inp_discount_price_vat.classList.remove('d-none');

              inp_discount.value = box_discount.value;
              inp_discount_type.value = box_discount_type.value;
            } else {
              inp_discount_price.classList.add('d-none');
              inp_discount_price.value = '';
              inp_discount_price_vat.classList.add('d-none');
              inp_discount_price_vat.value = '';

              inp_price.classList.remove('strikethrough');
              inp_price.classList.remove('form-control-sm');
              inp_price.removeAttribute('readonly');

              inp_price_vat.classList.remove('strikethrough');
              inp_price_vat.classList.remove('form-control-sm');
              inp_price_vat.removeAttribute('readonly');

              inp_discount.value = '';
              inp_discount_type.value = '';
            }

            inp_price.dispatchEvent(new Event('input'));
          }
        }
      }
    });
  }

  calcPriceWithVAT() {
    let row = event.target.closest('tr');
    let inp_quantity = row.querySelector("#position_quantity");
    let inp_price = row.querySelector("#position_price");
    let inp_price_with_vat = row.querySelector("#position_price_with_vat");
    let sel_vat = row.querySelector("#position_vat");

    let inp_discount_price = row.querySelector('#position_price_with_discount');
    let inp_discount_price_vat = row.querySelector('#position_price_with_discount_vat');
    let inp_discount = row.querySelector('#position_discount');

    let price = inp_price.value ? parseFloat(inp_price.value) : 0;
    let price_discounted = inp_discount_price.value ? parseFloat(inp_discount_price.value) : 0;
    let vat = parseFloat(sel_vat.value)
    inp_price_with_vat.value = (price*(1+(vat/100))).toFixed(2)

    if(inp_discount.value != '') {
      inp_discount_price_vat.value = (price_discounted*(1+(vat/100))).toFixed(2)
    }

    this.updatePositionTotal();
  }

  calcPriceWithoutVAT() {
    let row = event.target.closest('tr');
    let inp_quantity = row.querySelector("#position_quantity");
    let inp_price = row.querySelector("#position_price");
    let inp_price_with_vat = row.querySelector("#position_price_with_vat");
    let sel_vat = row.querySelector("#position_vat");

    let price = inp_price_with_vat.value ? parseFloat(inp_price_with_vat.value) : 0;
    let vat = parseFloat(sel_vat.value)
    inp_price.value = (price/(1+(vat/100))).toFixed(2)

    this.updatePositionTotal();
  }

  calcPriceWithDiscount() {
    let price = parseFloat(this.discount_priceTarget.value);
    let discount = parseFloat(this.discount_discountTarget.value);
    let discount_type = this.discount_typeTarget.value;
    if(discount_type == 'fix') {
      this.discount_price_discountedTarget.value = (price-discount).toFixed(2)
    } else {
      this.discount_price_discountedTarget.value = (price-(price/100*discount)).toFixed(2)
    }
  }

  calcPriceWithoutDiscount() {
    let price_discounted = parseFloat(this.discount_price_discountedTarget.value);
    let discount = parseFloat(this.discount_discountTarget.value);
    let discount_type = this.discount_typeTarget.value;
    if(discount_type == 'fix') {
      this.discount_priceTarget.value = (price_discounted+discount).toFixed(2)
    } else {
      this.discount_priceTarget.value = ((price_discounted*100)/(100-discount)).toFixed(2)
    }
  }

  updatePositionTotal() {
    let row = event.target.closest('tr');

    let inp_quantity = row.querySelector("#position_quantity");
    let inp_price = row.querySelector("#position_price");
    let inp_price_with_vat = row.querySelector("#position_price_with_vat");
    let inp_total = row.querySelector("#position_total");
    let inp_total_with_vat = row.querySelector("#position_total_with_vat");

    let inp_discount_price = row.querySelector('#position_price_with_discount');
    let inp_discount_price_vat = row.querySelector('#position_price_with_discount_vat');
    let inp_discount = row.querySelector('#position_discount');

    if(inp_quantity.value == '' && parseFloat(inp_quantity.value) <= 1) {
      inp_quantity.value = 1;
    }
    let quantity = inp_quantity.value ? parseFloat(inp_quantity.value) : 0;

    let price;
    let price_with_vat;
    if(inp_discount.value != '') {
      price = inp_discount_price.value ? parseFloat(inp_discount_price.value) : 0;
      price_with_vat = inp_discount_price_vat.value ? parseFloat(inp_discount_price_vat.value) : 0;
    } else {
      price = inp_price.value ? parseFloat(inp_price.value) : 0;
      price_with_vat = inp_price_with_vat.value ? parseFloat(inp_price_with_vat.value) : 0;
    }

    inp_total.value = (quantity * price).toFixed(2);
    inp_total_with_vat.value = (quantity * price_with_vat).toFixed(2);

    this.updateContractTotal()
  }

  updateContractTotal() {
    let that = this;

    let total = 0;
    let total_with_vat = 0;
    let total_vat = {};
    let total_vat_sum = {};
    let total_eqs = {};
    let total_eqs_sum = {};
    let total_retention = 0;
    let with_vat = false;

    this.total_vat_sumTargets.forEach((v, i) => {
      total_vat[v.dataset.vat] = 0;
      total_vat_sum[v.dataset.vat] = 0;
    });

    this.position_totalTargets.forEach((el, i) => {
      total += (el.value != '' ? parseFloat(el.value) : 0).round(2);
    });

    this.totalTarget.textContent = total.round(2).toFixed(2);

    this.position_vatTargets.forEach((el, i) => {
      let vat_str = el.value.toString();
      let tot = parseFloat(this.position_totalTargets[i].value);
      let tot_vat = parseFloat(this.position_total_with_vatTargets[i].value);
      //total_vat[vat_str] = (total_vat[vat_str] + (tot_vat - tot)).round(2);
      //total_vat_sum[vat_str] = (total_vat_sum[vat_str] + tot).round(2);
      total_vat_sum[vat_str] += tot
    })

    total_with_vat = total;

    this.total_vat_sumTargets.forEach((el, i) => {
      let sum = total_vat_sum[el.dataset.vat].round(2);
      let vat = parseFloat(el.dataset.vat);
      let vat_sum = (sum*(vat/100)).round(2);
      total_vat[el.dataset.vat] = vat_sum;

      total_with_vat += vat_sum;

      var vat_position = document.querySelector("#vat_"+el.dataset.vat.replace('.', '-'));
      if(total_vat_sum[el.dataset.vat].round(2) > 0) {
        vat_position.classList.remove('d-none');
        with_vat = true;
      } else {
        vat_position.classList.add('d-none');
      }
      el.textContent = sum.toFixed(2);
      document.querySelector("#total_vat_sum_"+el.dataset.vat.replace('.', '-')).value = sum.toFixed(2);

      document.querySelector("#total_vat_"+el.dataset.vat.replace('.', '-')).value = vat_sum.toFixed(2);
    })

    if(with_vat) {
      that.vats_summaryTarget.classList.add("border-bottom", "border-secondary");
    } else {
      that.vats_summaryTarget.classList.remove("border-bottom", "border-secondary");
    }

    this.total_vatTargets.forEach((el, i) => {
      el.textContent = total_vat[el.dataset.vat].toFixed(2);
      document.querySelector("#total_vat_"+el.dataset.vat.replace('.', '-')).value = el.textContent;
    })

    if(this.equivalence_surchargeTarget.checked) {

      this.total_eqsTargets.forEach((eq, i) => {
        let eqs = parseFloat(eq.dataset.eqs);
        total_eqs_sum[eq.dataset.eqs] = total_vat_sum[eq.dataset.vat];
        total_eqs[eq.dataset.eqs] = total_vat_sum[eq.dataset.vat]*(eqs/100);

        total_with_vat += total_eqs[eq.dataset.eqs];
      });

      this.total_eqs_sumTargets.forEach((el, i) => {
        el.textContent = total_eqs_sum[el.dataset.eqs].toFixed(2);
        document.querySelector("#total_vat_sum_"+el.dataset.eqs.replace('.', '-')).value = el.textContent;

        if(total_eqs_sum[el.dataset.eqs] > 0) {
          el.parentNode.parentNode.classList.remove("d-none");
          el.parentNode.parentNode.classList.add("d-flex");
        } else {
          el.parentNode.parentNode.classList.remove("d-flex");
          el.parentNode.parentNode.classList.add("d-none");
        }
      })

      this.total_eqsTargets.forEach((el, i) => {
        el.textContent = total_eqs[el.dataset.eqs].toFixed(2);
        document.querySelector("#total_vat_"+el.dataset.eqs.replace('.', '-')).value = el.textContent;
      })
    }

    let retention = parseFloat(this.retentionTarget.dataset.retention);
    if(this.retentionTarget.checked) {
      total_retention = (total.round(2)*(retention/100)).round(2);
      this.total_retentionTarget.textContent = (0-total_retention).toFixed(2);
      total_with_vat = (total_with_vat - total_retention);
    } else {
      total_retention = 0;
      this.total_retentionTarget.textContent = 0;
    }

/*
    this.position_total_with_vatTargets.forEach((el, i) => {
      total_with_vat += el.value != '' ? parseFloat(el.value) : 0;
    });
*/

    this.total_with_vatTarget.textContent = total_with_vat.toFixed(2);

    //Set form values
    document.querySelector("#contract_total").value = total.toFixed(2);
    document.querySelector("#contract_total_with_vat").value = total_with_vat.toFixed(2);
    document.querySelector("#contract_total_retention").value = total_retention.toFixed(2);
  }

  uploadAttachment() {
    let that = this;
    var input = event.target;
    const url = input.dataset.directUploadUrl;
    var files = input.files.length;

    let counter = parseInt(this.attachments_counterTarget.dataset.counter);

    Array.from(input.files).forEach(file => {
      const upload = new DirectUpload(file, url);
      that.attachment_uploadingTarget.classList.remove('d-none');

      upload.create((error, blob) => {
        if (error) {
          console.log(`ERROR: ${error}`);
        } else {
          Rails.ajax({
            url: `/${this.locale}/contract/attachment/add/${btoa(blob.signed_id)}`,
            type: "get",
            data: "",
            success: function(data) {
              counter += 1;
              that.attachments_counterTarget.innerText = counter;
              that.attachments_counterTarget.dataset.counter = counter;
              that.attachment_uploadingTarget.classList.add('d-none');
            },
            error: function(data) {}
          });
        }
      })
    })

    input.value = '';
  }

  deleteAttachment() {
    var attachment = event.target.closest("div.d-flex");
    attachment.remove();
    this.attachments_counterTarget.dataset.counter = parseInt(this.attachments_counterTarget.dataset.counter - 1)
    this.attachments_counterTarget.innerText = this.attachments_counterTarget.dataset.counter;
  }

  deleteConfirm() {
    let that = this;
    let uuid = event.target.dataset.uuid;
    let msg = `${I18n.t('contract.confirm.delete')}<br><br><span class="semi-bold">${event.target.dataset.reference}</span><br>${event.target.dataset.subject}`;
    console.log(msg)
    bootbox.dialog({
      message: msg,
      onEscape: true,
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        delete: {
          label: I18n.t('btn.delete'),
          className: "btn-danger",
          callback: function() {
            Rails.ajax({
              url: `/${that.locale}/contract/${uuid}/delete`,
              type: "delete",
              data: "",
              success: function(data) {},
              error: function(data) {}
            });
            window.location = `/${I18n.locale}/contracts`;
          }
        }
      }
    });
  }

  initData() {
    let that = this;
    let table = $("#contracts");

    $.fn.bootstrapTable.locales[this.locale]['formatShowingRows'] = function (from, to, total) {
      return I18n.t('contract.pagination', {from: from, to: to, total: total});
    }
    $.fn.bootstrapTable.locales[this.locale]['formatRecordsPerPage'] = function (count) {
      return I18n.t('contract.per_page', {count: count});
    }

    table.bootstrapTable({
      url: `${window.location.href}`,
      locale: this.locale,
      minHeight: '400',
      classes: 'table',
      search: true,
      pagination: true,
      paginationLoop: false,
      pageSize: 100,
      pageList: [50, 100, 250, 'All'],
      searchSelector: '#filter_search',
      searchText: this.filterTarget.value,
      searchTimeOut: 250,
      loadingFontSize: '1rem',
      sortName: 'reference',
      sortOrder: 'desc'
    });

    table.on('load-success.bs.table', () => {
      if(this.filterTarget.value != '') {
        this.filterTarget.focus();
        this.filterTarget.blur();
      }

      //Disable show/hide toggle for actions column
      let chks = document.querySelector('.fixed-table-toolbar').querySelectorAll('input[type=checkbox]');
      if(chks) {
        chks[chks.length-1].parentNode.classList.add('d-none');
      }
    });

    table.on("column-switch.bs.table", function() {
      let cols = JSON.stringify(table.bootstrapTable('getVisibleColumns').map(function (it) {
        return it.field
      }))
      Rails.ajax({
        url: `/${that.locale}/subscription/config`,
        type: "post",
        data: `parameter=contracts_list_columns&value=${cols}`,
        success: function(data) {},
        error: function(data) {}
      });
    });

    table.on('reset-view.bs.table', () => {

    });
  }

  initTableFunctions() {
    let that = this;
    I18n.locale = this.locale;
    let locale = this.locale;

    window.referenceFormatter = function(reference, contract) {
      return `
        <a href="/${locale}/contract/${contract.uuid}">
          ${reference}
        </a>
      `;
    }

    window.customerFormatter = function(customer, contract) {
      let str = ''
      if(customer && customer != '') {
        str = customer.split("\n")[0];
      }
      return str;
    }

    window.subjectFormatter = function(subject, contract) {
      if(subject) {
        if(subject.length > 60) {
          return `${subject.substr(0, 60)}&mldr;`;
        } else {
          return subject;
        }
      } else {
        return ''
      }
    }

    window.dateFormatter = function(date, contract) {
      let str = I18n.l("date.formats.default", date);
      if(this.field == 'dtend') {
        if(date) {
          let dt = new Date(date);
          let dt2 = new Date(dt.setDate(dt.getDate() - contract.notice_period))
          let today = new Date()
          if(dt2 < today) {
            str = '<i class="fa-regular fa-circle-exclamation text-danger"></i> ' + I18n.l("date.formats.default", date);
          }
        } else {
          str = ''
        }
      }
      return str;
    }

    window.statusFormatter = function(status) {
      let color = "text-dark";
      switch (status) {
        case 'accepted':
          color = "text-success";
          break;
        case 'active':
          color = "text-success";
          break;
        case 'archived':
          color = "text-dark";
          break;
        case 'draft':
          color = "text-secondary";
          break;
        case 'expired':
          color = "text-danger-emphasis";
          break;
        case 'ready':
          color = "text-primary";
          break;
        case 'rejected':
          color = "text-danger";
          break;
        case 'sent':
          color = "text-info-emphasis";
          break;
      }
      return `<span class="semi-bold ${color}">${I18n.t(`contract.status.${status}`)}</span>`;
    }

    window.actionsFormatter = function(data, contract) {
      return `
        <a class="btn btn-outline-secondary" href="/${locale}/contract/${contract.uuid}">
          <i class="fas fa-info-circle fa-fw"></i>
        </a>
        <div class="btn-group" role="group">
          <a class="btn btn-outline-secondary" href="/${locale}/contract/${contract.uuid}/edit">
            <i class="fas fa-edit"></i>
          </a>
          <button type="button" class="btn btn-outline-danger" data-uuid="${contract.uuid}" data-reference="${contract.reference}" data-subject="${contract.subject}" data-action="click->contracts#deleteConfirm">
            <i class="fas fa-trash"></i>
          </button>
        </div>
      `
    }
  }

  initItemSelectDialog() {
    let that = this;

    if(!document.querySelector('#itemSelectDialog')) {
      return
    }
    this.itemSelectDialog = new bootstrap.Modal(document.querySelector('#itemSelectDialog'), {
      keyboard: true,
      focus: true
    });

    let table = $('#itemSelect').bootstrapTable({
      locale: this.locale,
      url: `/${this.locale}/orders/items`,
      search: true,
      loadingFontSize: '1rem',
      classes: 'table',
      searchTimeOut: 500,
      clickToSelect: true,
      showColumns: true,
    });

    document.querySelector('#itemSelectDialog div.search').classList.add('w-75');
    document.querySelector('#itemSelectDialog input.search-input').style.height = '34px';

    that.vat_select = '';
    Rails.ajax({
      url: `/${that.locale}/orders/vats`,
      type: "get",
      data: "",
      success: function(data) {
        that.vat = data;
        /*
        Object.keys(data).forEach((country, i) => {
          that.vat_select += `<optgroup label="${I18n.t(`countries.${country}`)}">`;
          data[country].forEach((vat, i) => {
            that.vat_select += `<option value="${vat.value.toFixed(1)}">${vat.label}</option>`;
          });
          that.vat_select += "</optgroup>";
        });
        */
      },
      error: function(data) {
      }
    });

    that.warehouse_select = '';
    Rails.ajax({
      url: `/${that.locale}/warehouses`,
      type: "get",
      data: "",
      success: function(data) {
        that.warehouses = data;
        data.forEach((warehouse, i) => {
          that.warehouse_select += `<option value="${warehouse.uuid}" style="color: ${warehouse.data.color}">${warehouse.name}</option>`
        });
      },
      error: function(data) {
      }
    });

    table.on("column-switch.bs.table", function() {
      let cols = JSON.stringify(table.bootstrapTable('getVisibleColumns').map(function (it) {
        return it.field
      }))

      Rails.ajax({
        url: `/${that.locale}/subscription/config`,
        type: "post",
        data: `parameter=orders_items_select_columns&value=${cols}`,
        success: function(data) {},
        error: function(data) {}
      });
    });

    let dialog = document.querySelector('#itemSelectDialog');
    let btnSelect = dialog.querySelector("button#btnSelect");
    btnSelect.addEventListener('click', function (event) {
      btnSelect.disabled = true;
      let icon = document.createElement("i");
      icon.classList.add("fas", "fa-spinner", "fa-pulse");
      btnSelect.prepend(icon);

      setTimeout(() => {
        let items = table.bootstrapTable('getSelections');
        if(items.length > 0) {
          that.addSelectedItems(items);
        }
      }, 50);

      setTimeout(() => {
        that.itemSelectDialog.hide();
        let icon2 = btnSelect.querySelector('svg');
        btnSelect.removeChild(icon2);
        btnSelect.disabled = false;

        dialog.querySelectorAll("button[data-loaded='true']").forEach((btn, i) => {
          let tab = btn.parentNode.querySelector('table');
          tab.innerHTML = '';
          btn.dataset.loaded = 'false';
        });

        table.bootstrapTable('uncheckAll');
        table.bootstrapTable('refresh', {silent: true});
      }, 250)
    });

    window.itemNameFormatter = function(name, item) {
      return `${name}&nbsp;&nbsp;<span class="small text-muted">${[item.size, item.color].filter(item => item).join(' | ')}</span>`
    }

    window.itemStockFormatter = function(stock, item) {
      if(stock == null) {
        stock = '-'
      }
      if(item.with_stock) {
        return `
          <button type="button" class="btn btn-outline-light btn-sm text-dark" data-action="click->items#loadStockQuantities" data-uuid="${item.uuid}" data-loaded="false">
            ${stock}
          </button>
          <table class="small table-borderless table-sm warehouse-quantities"></table>
        `
      } else {
        return ''
      }
    }

    window.itemStatusFormatter = function(status, item) {
      let str = '';
      if(item.statusname) {
        if(item.status_subscription_id) {
          str += item.statusname;
        } else {
          str += I18n.t(`item.states.${item.statusname}`);
        }
      }
      return str
    }

    window.itemPriceFormatter = function(price, item) {
      if(price && !isNaN(price)) {
        if(item.price_with_discount) {
          return `
            <span class="text-danger">
              ${that.currenciesFormat(item.price_with_discount.toFixed(2), item.currency)}
              </span>
            <br>
            <small class="text-muted">
              ${that.currenciesFormat(item.price_with_vat.toFixed(2), item.currency)}
            </small>
          `
        } else {
          return `
            ${that.currenciesFormat(price.toFixed(2), item.currency)}
            <br>
            <small class="text-muted">
              ${that.currenciesFormat(item.price_with_vat.toFixed(2), item.currency)}
            </small>
          `
        }
      } else {
        return ''
      }
    }

    window.itemReservedFormatter = function(reserved, item) {
      if(item.with_stock && item.reserved && item.reserved > 0) {
        return reserved
      } else {
        return ''
      }
    }

    window.itemAvailabilityFormatter = function(available, item) {
      if(item.with_stock) {
        return item.availability
      } else {
        return ''
      }
    }
  }

  initInventoryItemSelectDialog() {
    let that = this;

    if(!document.querySelector('#inventoryItemSelectDialog')) {
      return
    }
    this.inventoryItemSelectDialog = new bootstrap.Modal(document.querySelector('#inventoryItemSelectDialog'), {
      keyboard: true,
      focus: true
    });

    let table = $('#inventoryItemSelect').bootstrapTable({
      locale: this.locale,
      url: `/${this.locale}/inventory`,
      search: true,
      loadingFontSize: '1rem',
      classes: 'table',
      searchTimeOut: 500,
      clickToSelect: true,
      showColumns: true,
    });

    document.querySelector('#inventoryItemSelectDialog div.search').classList.add('w-75');
    document.querySelector('#inventoryItemSelectDialog input.search-input').style.height = '34px';

    let dialog = document.querySelector('#inventoryItemSelectDialog');
    let btnSelect = dialog.querySelector("button#btnSelect");
    btnSelect.addEventListener('click', function (event) {
      btnSelect.disabled = true;
      let icon = document.createElement("i");
      icon.classList.add("fas", "fa-spinner", "fa-pulse");
      btnSelect.prepend(icon);

      setTimeout(() => {
        let items = table.bootstrapTable('getSelections');
        if(items.length > 0) {
          that.addSelectedInventoryItems(items);
        }
      }, 50);

      setTimeout(() => {
        that.inventoryItemSelectDialog.hide();
        let icon2 = btnSelect.querySelector('svg');
        btnSelect.removeChild(icon2);
        btnSelect.disabled = false;

        dialog.querySelectorAll("button[data-loaded='true']").forEach((btn, i) => {
          let tab = btn.parentNode.querySelector('table');
          tab.innerHTML = '';
          btn.dataset.loaded = 'false';
        });

        table.bootstrapTable('uncheckAll');
        table.bootstrapTable('refresh', {silent: true});
      }, 250)
    });


    window.inventoryItemContactFormatter = function(contact, item) {
      let out = ''
      if(item.company && item.company != '') {
        out = item.company
      }
      if(item.lastname || item.firstname) {
        out = [item.firstname, item.lastname].join(' ')
      }
      return out;
    }

    window.inventoryItemContractFormatter = function(contracts, item) {
      if(contracts) {
        return contracts
      } else {
        return ''
      }
    }

    window.inventoryItemStatusFormatter = function(status, item) {
      if(status) {
        return I18n.t(`inventory.status.${status}`)
      } else {
        return ''
      }
    }

    window.inventoryUsecaseFormatter = function(usecase, item) {
      if(usecase) {
        return I18n.t(`inventory.usecases.${usecase}`)
      } else {
        return ''
      }
    }

  }
}
