import { Controller } from "stimulus"
import { TempusDominus } from "@eonasdan/tempus-dominus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {

  static targets = [ "filter", "supplier", "address", "date", "state", "address_select", "address_select_div", "currency", "currency_abbrvs", "addButton", "item_total", "item_vat", "item_total_with_vat", "total_vat", "total_vat_sum", "total", "transport", "total_with_vat", "total_transport", "taxes", "total_taxes", "customs", "total_customs", "vats_summary", "locale", "item_position", "warehouse", "attachments_counter", "attachment_uploading", "addButton", "addButtonIconPlus", "addButtonIconSpinner"]

  static values = {
    currency: String,
    currencyFormat: String,
    currencyAbbr: String
  }

  initialize() {
    if(this.element.getAttribute("data-purchase-vats")) {
      this.vats = this.element.getAttribute("data-purchase-vats").split(';');
    }

    if(this.element.getAttribute("data-copy")) {
      this.newPurchaseFromExisting();
    }

    if(this.element.getAttribute("data-locale")) {
      this.locale = this.element.getAttribute("data-locale");
      I18n.locale = this.locale;
    }

    if(this.element.getAttribute("data-vat-label")) {
      this.vat_label = this.element.getAttribute("data-vat-label");
    }

    if(this.element.getAttribute("data-page")) {
      this.page = this.element.getAttribute("data-page");
    }
  }

  connect() {
    if(this.page == 'index' || this.page == 'archive') {
      this.initTableFunctions();
      this.initData();

      let filter;
      if(this.page == 'archive') {
        filter = Cookies.get('filter_purchases_archive');
      } else {
        filter = Cookies.get('filter_purchases');
      }

      if(filter && this.hasFilterTarget) {
        this.filterTarget.value = filter;
        this.filterPurchases();
      }
    }

    if(this.element.getAttribute("data-uuid")) {
      this.loadPurchaseOrder();
    }

    const queryString = window.location.search;
    if(queryString != '') {
      const urlParams = new URLSearchParams(queryString);
      let param = urlParams.get('tab');
      if(param) {
        let btn = document.querySelector(`button[data-bs-target="#nav-${param}"]`)
        if(btn) {
          var tab = new bootstrap.Tab(btn)
          tab.show();
        }
      }
    }

    let that = this;

    var sp_state = $("#purchase_state").selectpicker({ width: '200px'});

    var sp_sup = $("#purchase_supplier").selectpicker({ width: '100%'});
    sp_sup.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
      that.supplierSelected(e.target.value);
    });

    var sp_curr = $(".selectpicker.currency").selectpicker({ width: '250px'});
    sp_curr.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
      let items = document.querySelector(".items").querySelectorAll(".card");
      that.updateItemPrices(items, event.target.value);
    });

    if(this.hasWarehouseTarget) {
      var sp_wh = $(this.warehouseTarget).selectpicker({ width: '250px'});
      sp_wh.selectpicker().on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        that.setWarehouse(e);
      });
    }

    if(this.hasAddress_selectTarget) {
      $(this.address_selectTarget).selectpicker().on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        that.addressTarget.value = e.target.options[clickedIndex].dataset.address;
      });
    }

    let total_inp = document.querySelector("#purchase_total");
    if(total_inp && total_inp.value != '') {
      this.updateTotal();
    }

    $("#purchase_locale").selectpicker({ width: '150px'});

    if(document.querySelector('#datetimepicker1')) {
      new TempusDominus(document.getElementById('datetimepicker1'), {
        localization: {
          locale: this.locale
        },
        useCurrent: true,
        display: {
          components: {
            date: true,
            decades: true,
            month: true,
            year: true,
            hours: false,
            seconds: false,
            minutes: false
          }
        }
      });
    }

    if(this.page == "new" || this.page == "edit") {
      let items = document.querySelector("#items");
      if(items) {
        this.enableItemSorting();
      }
    }

    document.querySelectorAll(".trix-content").forEach((div) => {
      div.querySelectorAll("a").forEach((link) => {
        link.setAttribute("target", "_blank");
      });
    });

    // TODO: Remove
    $('a.btn2').on('click', (e) => {
      let i = e.target.querySelector('i');
      if(i && !e.target.dataset.toggle) {
        e.target.classList.add('disabled');
        i.classList.remove(i.classList[1])
        i.classList.add('fa-spinner', 'fa-spin')
      }
    })

    this.initTableFunctions();

    $('#loading').fadeOut(200);

    if(this.page == 'edit') {
      this.initItemSelectDialog();
    }
  }

  filterPurchases() {
    if(this.page == 'archive') {
      Cookies.set('filter_purchases_archive', this.filterTarget.value.toLowerCase());
    } else {
      Cookies.set('filter_purchases', this.filterTarget.value.toLowerCase());
    }
  }

  clearFilter() {
    this.filterTarget.value = '';

    if(this.page == 'archive') {
      Cookies.expire('filter_purchases_archiv');
    } else {
      Cookies.expire('filter_purchases');
    }

    $("#purchases").bootstrapTable('resetSearch', '');
    this.filterTarget.focus();
    this.filterTarget.blur();
  }

  currenciesAbbr(currency) {
    if(this.currencyAbbrValue == 'iso4217') {
      return currency.toUpperCase();
    } else {
      return I18n.t(`currencies.abbr.${currency}`);
    }
  }

  currenciesFormat(value, currency) {
    if(this.currencyFormatValue == '%u %n') {
      return `${this.currenciesAbbr(currency)} ${value}`
    } else {
      return `${value} ${this.currenciesAbbr(currency)}`
    }
  }

  supplierSelected(uuid) {
    let that = this;

    this.initItemSelectDialog();

    //Get contact details
    Rails.ajax({
      url: `/${this.locale}/contact/${uuid}.json`,
      type: "get",
      data: "",
      success: function(data) {
        that.setSupplierAddress(data);

        if(data.data && data.data.locale) {
          that.localeTarget.value = data.data.locale;
          $(that.localeTarget).selectpicker('refresh');
        }
      },
      error: function(data) {}
    });

    this.addButtonTarget.classList.remove('disabled');
    this.addButtonTarget.disabled = false;
    this.addButtonTarget.parentNode.querySelector('span.text-muted').classList.add('d-none');
  }

  setSupplierAddress(data) {
    let addr_field = document.querySelector("#purchase_direction");
    let name = '';
    let addr = '';
    var taxid = '';
    var fiscal_code = '';
    var unique_code = '';
    var cem = '';
    if(data.company.trim() != '') {
      addr += data.company+"\n";
      name += data.company+"\n";
    }
    if(data.firstname != '' || data.lastname != '') {
      addr += (`${data.firstname} ${data.lastname}`).trim()+"\n";
      name += (`${data.firstname} ${data.lastname}`).trim()+"\n";
    }
    if(data.addresses.length > 0) {
      addr += data.addresses[0].street+"\n";
      addr += data.addresses[0].zip+" "+data.addresses[0].location;
      if(data.addresses[0].state) {
        addr += "\n"+data.addresses[0].state;
      }
      if(data.addresses[0].country) {
        addr += "\n"+countries.getName(data.addresses[0].country, this.locale);
      }
    }
    if(data.taxid != '' && data.taxid != null) {
      addr += `\n\n${this.vat_label}: ${data.taxid}`;
      taxid = `\n\n${this.vat_label}: ${data.taxid}`;
    }
    if(data.data && data.data.fiscal_code && data.data.fiscal_code != '') {
      addr += `\n${I18n.t("contact.fiscal_code_abbr")}: ${data.data.fiscal_code}`;
      fiscal_code = `\n${I18n.t("contact.fiscal_code_abbr")}: ${data.data.fiscal_code}`;
    }
    if(data.data && data.data.unique_code && data.data.unique_code != '') {
      addr += `\n${I18n.t("contact.unique_code")}: ${data.data.unique_code}`;
      unique_code = `\n${I18n.t("contact.unique_code")}: ${data.data.unique_code}`
    }

    if(data.emails.length > 0) {
      data.emails.forEach((email) => {
        if(email.email_type == 'cem') {
          addr += `\n${I18n.t("contact.cem")}: ${email.email_address}`;
          cem = `\n${I18n.t("contact.cem")}: ${email.email_address}`;
        }
      })
    }

    this.addressTarget.value = addr;
    this.addressTarget.dataset.groups = data.groups;

    if(data.addresses.length > 1) {
      this.address_selectTarget.innerHTML = '';

      for(let i = 0; i < data.addresses.length; i++) {

        let addr_dir = data.addresses[i].street+"\n";
        addr_dir += data.addresses[i].zip+" "+data.addresses[i].location;
        if(data.addresses[i].state) {
          addr_dir += "\n"+data.addresses[i].state;
        }
        if(data.addresses[i].country) {
          addr_dir += "\n"+countries.getName(data.addresses[i].country, this.locale);
        }

        let addr = `${name}${addr_dir}`;
        if(taxid != '') {
          addr += taxid;
        }
        if(fiscal_code != '') {
          addr += fiscal_code;
        }
        if(unique_code != '') {
          addr += unique_code;
        }
        if(cem != '') {
          addr += cem;
        }

        this.address_selectTarget.innerHTML += `<option data-address="${addr}">${addr_dir.replace('\n', ', ')}</option>`;
      }

      $(this.address_selectTarget).selectpicker('refresh');
      this.address_select_divTargets.forEach((div) => {
        div.classList.remove("d-none");
      })

    } else {
      this.address_select_divTargets.forEach((div) => {
        div.classList.add("d-none");
      })

      this.address_selectTarget.innerHTML = '';
      $(this.address_selectTarget).selectpicker('refresh');
    }
  }

  addItem() {
    var table = $('#itemSelect').bootstrapTable({});
    this.itemSelectDialog.show();

    let dialog = document.querySelector('#itemSelectDialog');

    dialog.addEventListener('shown.bs.modal', function (event) {
      event.target.querySelector(".search-input").focus();
    })
  }

  addSelectedItems(items) {
    let vats;
    let that = this;
    let div_items = document.querySelector("#items");

    let currency = this.currency;
    if(this.hasCurrencyTarget) {
      currency = this.currencyTarget.value;
    }

    let order_locale = this.locale;
    if(this.hasLocaleTarget) {
      order_locale = this.localeTarget.value;
    }

    this.toggleWithVAT();

    let supplier = this.supplierTarget.value;

    items.forEach((item, i) => {
      let card = document.createElement("div");

      card.classList.add("card", "mb-3");
      card.dataset.target = "purchases.item";
      card.dataset.prices = item.prices;

      let price = 0;
      let total = 0;
      let total_with_vat = 0;
      let vat = 0;
      let order_number = '';
      let order_quantity = 1;
      if(item.suppliers) {
        let sups = item.suppliers.filter(sup => sup.contact == supplier);
        if(sups.length > 0) {
          if(sups[0].price) {
            price = sups[0].price;
            total = price;
            vat = sups[0].vat ? sups[0].vat : 0;
            order_number = sups[0].order_number;
            order_quantity = sups[0].order_quantity ? sups[0].order_quantity : 0;
          }
        }
      }

      card.innerHTML = `
        <input id="item_uuid" name="items[][uuid]" type="hidden" value="${item.uuid}">
        <div class="d-flex justify-content-between bg-secondary bg-opacity-25">
          <div>
            <div class="handle ps-2 pt-2 float-start pointer-move-up-down">
              <i class="fas fa-grip-vertical fa-lg"></i>
            </div>
            <span class="badge bg-secondary ms-2 mt-2" data-purchases-target="item_position">
            </span>
          </div>

          <div>
            <button type="button" class="btn btn-sm btn-outline-secondary me-1 mt-1 mb-1" data-bs-toggle="collapse" data-bs-target="#collapseNote-${item.uuid}" aria-expanded="false" aria-controls="collapseNote">
              <i class="far fa-edit"></i> ${I18n.t('item.note')}
            </button>
            <button type="button" class="btn btn-sm btn-outline-danger me-1 mt-1 mb-1" data-action="click->purchases#deleteItem">
              <i class="fas fa-trash"></i>
            </button>
          </div>
        </div>
        <div class="card-body pt-1 pb-0">
          <div class="d-flex justify-content-between align-items-center">
            <div class="form-group mb-3 flex-fill me-2">
              <label for="order_uuid" data-label="${item.i18n_name}">
                ${item.i18n_name} ${(item.number && item.number != '') ? ` | ${item.number}` : ''}
              </label>
              <input id="item_label" name="items[][label]" class="form-control" type="text" value="${item.i18n_name}">
            </div>
            <a class="btn btn-outline-secondary float-end mt-2 ms-0" target="_blank" id="btn_item_open" href="/${this.locale}/item/${item.uuid}">
              <i class="fas fa-external-link-alt"></i>
            </a>
          </div>

          <div class="d-flex justify-content-between">
            <div class="form-group mb-3 me-3 w-auto" style="min-width: 150px;">
              <label for="item_order_number">
                ${I18n.t('item.number')}
              </label><br>
              <input id="item_order_number" name="items[][order_number]" class="form-control form-control-sm" type="text" value="${order_number}">
            </div>
            <div class="form-group mb-3 me-3 w-auto" style="min-width: 150px;">
              <label for="item_warehouse">
                ${I18n.t('warehouse.warehouse')}
              </label><br>
              <select id="item_warehouse" name="items[][warehouse]" class="selectpicker warehouse form-control form-control-sm me-auto w-100" data-selected="${that.warehouseTarget.value}">
                ${that.warehouse_select}
              </select>
            </div>
            <div class="form-group mb-3 me-3">
              <label for="item_quantity">
                ${I18n.t('purchase.item.quantity')}
              </label>
              <input id="item_quantity" name="items[][quantity]" class="form-control form-control-sm text-end" style="width: 100px;" step="0.01" data-action="input->purchases#updatePrice" type="number" value="${order_quantity}">
            </div>
            <div class="form-group mb-3 me-3">
              <label for="item_price_with_price">
                ${I18n.t('purchase.item.price')}
              </label>
              <div class="input-group input-group-sm">
                <button class="btn btn-outline-info multiple-prices d-none" type="button" data-container="body" data-toggle="popover" data-placement="top" data-trigger="focus" data-html="true">
                  <i class="fas fa-tags"></i>
                </button>
                <input id="item_price" name="items[][price]" class="form-control form-control-sm text-end" style="width: 100px;" step="0.001" data-action="input->purchases#updatePrice" type="number" value="${price.toFixed(2)}">
                <div class="input-group-text bg-white" data-purchases-target="currency_abbrvs">
                  ${that.currenciesAbbr(currency)}
                </div>
              </div>
              <span id="customer_price" class="badge bg-primary d-none">
                ${I18n.t('item.customer_price')}
              </span>
            </div>
            <div class="form-group mb-3">
              <label for="item_vat">
                ${I18n.t('item.vat')}
              </label><br>

              <select id="item_vat" name="items[][vat]" class="selectpicker vat form-control form-control-sm" data-purchases-target="item_vat">
                ${that.vat_select}
              </select>
            </div>
            <div class="flex-fill">
            </div>
            <div class="form-group mb-3 items-totals">
              <label for="item_total" class="semi-bold">
                ${I18n.t('item.total')}
              </label>
              <div class="input-group input-group-sm">
                <input id="item_total" name="items[][total]" class="form-control form-control-sm text-end" style="width: 100px;" step="0.001" data-purchases-target="item_total" type="number" value="${total.toFixed(2)}">
                <div class="input-group-text bg-white" data-purchases-target="currency_abbrvs">
                  ${I18n.t(`currencies.abbr.${currency}`)}
                </div>
              </div>
            </div>
            <div class="form-group mb-3 items-totals-with-vat d-none">
              <label for="item_total_with_vat" class="semi-bold">
                ${I18n.t('item.total_with_vat')}
              </label>
              <div class="input-group input-group-sm">
                <input id="item_total_with_vat" name="items[][total_with_vat]" class="form-control form-control-sm text-end" style="width: 100px;" step="0.001" data-purchases-target="item_total_with_vat" type="number" value="${total_with_vat.toFixed(2)}">

                <div class="input-group-text bg-white" data-purchases-target="currency_abbrvs">
                  ${I18n.t(`currencies.abbr.${currency}`)}
                </div>
              </div>
            </div>
          </div>
          <div class="collapse ${item.note_text ? "show" : "" }" id="collapseNote-${item.uuid}">
            <div class="form-group me-3 mt-auto mb-3">
              <label for="purchase_note">
                ${I18n.t('item.note')}
              </label>
              <textarea id="item_note" name="items[][note]" class="form-control form-control-sm" rows="3">${item.note_text}</textarea>
            </div>
          </div>
        </div>
      `

      div_items.appendChild(card);

      let select_wh = card.querySelector(".selectpicker.warehouse");
      let select_vat = card.querySelector(".selectpicker.vat");
      select_vat.value = item.vat.toString();
      select_wh.value = that.warehouseTarget.value;

      let sp_wh = $(select_wh).selectpicker({ width: 'auto' });
      let sp_vat = $(select_vat).selectpicker({ width: '80px' });
      sp_vat.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        that.updatePrice();
      });

    });

    this.refreshItemNumbering();
  }

  deleteItem() {
    event.target.closest('.card').remove();
    this.updateTotal();
    this.refreshItemNumbering();
  }

  getItemPrice(uuid, card, currency) {
    let that = this;

    Rails.ajax({
      url: `/${this.locale}/item/${uuid}.json?currency=${currency}`,
      type: "get",
      data: "",
      success: function(data) {
        var order_number = card.querySelector("#item_order_number");
        var quantity = card.querySelector("#item_quantity");
        var price = card.querySelector("#item_price");
        var note = card.querySelector("#item_note");

        if(!quantity.value || quantity.value == '' || quantity.value == '0') {
          quantity.value = 1;
        }
        if(data.suppliers.length > 0) {
          for(let i = 0; i < data.suppliers.length; i++) {
            if(data.suppliers[i].contact == that.supplierTarget.value) {
              if(data.suppliers[i].order_number) {
                order_number.value = data.suppliers[i].order_number;
              } else {
                if(data.producer_number) {
                  order_number.value = data.producer_number;
                }
              }

              if(data.suppliers[i].order_quantity) {
                if(quantity.value == '') {
                  quantity.value = data.suppliers[i].order_quantity;
                }
              }
              if(data.suppliers[i].price) {
                if(data.suppliers[i].currency == that.currencyTarget.value) {
                  price.value = data.suppliers[i].price;
                } else {
                  price.value = data.suppliers[i].price_in_currency;
                }
              }
            }
          }
        }

        that.updatePrice(card);
      },
      error: function(data) {}
    });
  }

  updatePrice(card) {
    if(card instanceof Event || typeof card === 'undefined') {
      card = event.target.closest('.card');
    }
    let quantity = card.querySelector("#item_quantity");
    let price = card.querySelector("#item_price");
    let vat = card.querySelector("#item_vat");
    let total = card.querySelector("#item_total");
    let total_with_vat = card.querySelector("#item_total_with_vat");

    total.value = (price.value * quantity.value).toFixed(2);
    total_with_vat.value = (total.value * (1+(vat.value/100))).toFixed(2);

    this.updateTotal();
  }

  updateItemPrices(items, currency) {
    var currency_abbr = document.querySelector('[data-id="purchase_currency"]').getAttribute("title").split('(')[1].replace(')', '');

    items.forEach((card) => {
      let uuid = card.querySelector("#item_uuid").value;

      if(uuid != '') {
        this.getItemPrice(uuid, card, currency);
      }
    })

    this.currency_abbrvsTargets.forEach((lbl) => {
      lbl.textContent = currency_abbr;
    });
  }

  updateTotal() {
    let that = this;

    let total = 0;
    let total_with_vat = 0;
    let total_vat = {};
    let total_vat_sum = {};
    let total_eqs = {};
    let total_eqs_sum = {};
    let total_retention = 0;
    let with_vat = false;

    this.vats.forEach((v, i) => {
      total_vat[v] = 0;
      total_vat_sum[v] = 0;
    });

    this.item_totalTargets.forEach((el, i) => {
      total += parseFloat(el.value);
    })

    this.item_total_with_vatTargets.forEach((el, i) => {
      total_with_vat += parseFloat(el.value);
    })

    this.item_vatTargets.forEach((el, i) => {
      let vat_str = el.value.toString();
      let tot = this.item_totalTargets[i].value;
      let tot_vat = this.item_total_with_vatTargets[i].value;
      total_vat[vat_str] = total_vat[vat_str] + (tot_vat - tot);
      total_vat_sum[vat_str] = total_vat_sum[vat_str] + parseFloat(tot);
    })

    this.totalTarget.textContent = total.toFixed(2);

    this.total_vat_sumTargets.forEach((el, i) => {
      var vat_position = document.querySelector("#vat_"+el.dataset.vat.replace('.', '-'));
      if(total_vat_sum[el.dataset.vat].toFixed(2) > 0) {
        vat_position.classList.remove('d-none');
        with_vat = true;
      } else {
        vat_position.classList.add('d-none');
      }
      el.textContent = total_vat_sum[el.dataset.vat].toFixed(2);
      document.querySelector("#total_vat_sum_"+el.dataset.vat.replace('.', '-')).value = el.textContent;
    })

    if(with_vat) {
      that.vats_summaryTarget.classList.add("border-bottom", "border-secondary");
    } else {
      that.vats_summaryTarget.classList.remove("border-bottom", "border-secondary");
    }

    this.total_vatTargets.forEach((el, i) => {
      el.textContent = total_vat[el.dataset.vat].toFixed(2);
      document.querySelector("#total_vat_"+el.dataset.vat.replace('.', '-')).value = el.textContent;
    })

    if(this.transportTarget.value != '') {
      document.querySelector("#total_transport").classList.remove("d-none");
      this.total_transportTarget.innerText = parseFloat(this.transportTarget.value).toFixed(2);

      total_with_vat = total_with_vat + parseFloat(this.transportTarget.value);
    } else {
      document.querySelector("#total_transport").classList.add("d-none");
    }

    if(this.taxesTarget.value != '') {
      document.querySelector("#total_taxes").classList.remove("d-none");
      this.total_taxesTarget.innerText = parseFloat(this.taxesTarget.value).toFixed(2);

      total_with_vat = total_with_vat + parseFloat(this.taxesTarget.value);
    } else {
      document.querySelector("#total_taxes").classList.add("d-none");
    }

    if(this.customsTarget.value != '') {
      document.querySelector("#total_customs").classList.remove("d-none");
      this.total_customsTarget.innerText = parseFloat(this.customsTarget.value).toFixed(2);

      total_with_vat = total_with_vat + parseFloat(this.customsTarget.value);
    } else {
      document.querySelector("#total_customs").classList.add("d-none");
    }

    this.total_with_vatTarget.textContent = total_with_vat.toFixed(2);

    //Set form values
    this.total_with_vatTarget.value = total_with_vat.toFixed(2);
    document.querySelector("#purchase_total").value = total.toFixed(2);
    document.querySelector("#purchase_total_with_vat").value = total_with_vat.toFixed(2);
  }

  loadPurchaseOrder() {
    let that = this;
    let customer = this.supplierTarget.value;
    let purchaseState = this.stateTarget.dataset.state;

    //Get contact details
    Rails.ajax({
      url: `/${this.locale}/contact/${customer}.json`,
      type: "get",
      data: "",
      success: function(data) {
        //ordersController.setCustomerAddress(data);
      },
      error: function(data) {}
    });

    let purchase_locale = this.locale;
    if(this.hasLocaleTarget) {
      purchase_locale = this.localeTarget.value;
    }

    //Set items
    document.querySelectorAll(".card").forEach((card, i) => {
      let uuid = card.querySelector("#item_uuid").value;
      var sp_warehouse = $(".selectpicker.warehouse").selectpicker({ width: '100%' });
      that.refreshWarehouse(card);

      sp_warehouse.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        let card = e.target.closest('.card');
        that.refreshWarehouse(card);
      });

      card.querySelector("#btn_item_open").href = card.querySelector("#btn_item_open").href+uuid;

      if(uuid == '') {
        uuid = 'null';
      }

      Rails.ajax({
        url: `/${this.locale}/item/${uuid}.json`,
        type: "get",
        data: "",
        success: function(data) {
          let label = card.querySelector("#item_label").parentElement.querySelector('label');
          label.innerText = data.name;

          if(purchase_locale != that.locale) {
            data.i18n.forEach( (i18n) => {
              if(i18n.locale == purchase_locale) {
                label.innerText = i18n.name;
              }
            })
          }

          if(data.number) {
            label.innerText += ` | ${data.number}`;
          }
        },
        error: function(data) {
          card.querySelector(".selectpicker.item").parentElement.classList.add("d-none");
        }
      });

      if(['30_ordered', '40_shipped', '50_received', '49_received_partial', '60_completed'].includes(purchaseState)) {
        card.querySelectorAll("input").forEach((item, i) => {
          item.readOnly = true;
          item.classList.add('bg-white');
        });

        card.querySelector(".btn-outline-danger").classList.add("d-none");
        card.querySelector(".btn-outline-info").classList.add("d-none");
      }
    });

    var sp_vat = $(".selectpicker.vat").selectpicker({ width: '80px' });
    sp_vat.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
      that.updatePrice();
    });

    if(this.hasAddButtonTarget) {
      this.addButtonTarget.classList.remove('disabled');
      this.addButtonTarget.disabled = false;
    }

    this.updateTotal();
    this.refreshItemNumbering();
  }

  enableItemSorting() {
    let that = this
    new Sortable(items, {
        handle: '.handle',
        animation: 150,
        onEnd: () => {
          that.refreshItemNumbering();
        }
    });
  }

  deleteConfirm() {
    let that = this
    var uuid = event.target.dataset.uuid;
    var msg = `<span class="lead semi-bold">${event.target.dataset.purchaseref}</span><br>${event.target.dataset.supplier}`;
    bootbox.dialog({
      message: `${I18n.t('purchase.confirm.delete')}<br><br>${msg}`,
      onEscape: true,
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        delete: {
          label: I18n.t('btn.delete'),
          className: "btn-danger",
          callback: function() {
            Rails.ajax({
              url: `/${that.locale}/purchase/${uuid}/delete`,
              type: "delete",
              data: "",
              success: function(data) {},
              error: function(data) {}
            });
            bootbox.hideAll();
          }
        }
      }
    });
  }

  deleteDocumentConfirm() {
    let that = this;
    let msg = `${I18n.t('document.purchase_order')} ${event.target.dataset.reference}`;
    let uuid = event.target.dataset.uuid;
    bootbox.dialog({
      message: `${I18n.t('document.confirm.delete')}<br><br>${msg}`,
      onEscape: true,
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        delete: {
          label: I18n.t('btn.delete'),
          className: "btn-danger",
          callback: function() {
            Rails.ajax({
              url: `/${that.locale}/document/${uuid}/delete`,
              type: "delete",
              data: "",
              success: function(data) {},
              error: function(data) {}
            });
            location.reload();
          }
        }
      }
    });
  }

  completeConfirm() {
    let that = this
    var uuid = event.target.dataset.uuid;
    var msg = `<span class="lead semi-bold">${event.target.dataset.purchaseref}</span><br>${event.target.dataset.supplier}`;
    bootbox.dialog({
      message: `${I18n.t('purchase.confirm.complete')}<br><br>${msg}`,
      onEscape: true,
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        delete: {
          label: I18n.t('btn.complete'),
          className: "btn-success",
          callback: function() {
            Rails.ajax({
              url: `/${that.locale}/purchase/${uuid}/state/completed`,
              type: "post",
              data: "",
              success: function(data) {},
              error: function(data) {}
            });
            bootbox.hideAll();
          }
        }
      }
    });
  }

  stateConfirm() {
    let that = this
    var uuid = event.target.dataset.uuid;
    var state = event.target.dataset.state;
    var stateDescription = event.target.dataset.stateDescription;
    var msg = `<span class="lead">${I18n.t('purchase.purchase')}: ${event.target.dataset.purchaseref}<br>${I18n.t('purchase.state')}: <span class="semi-bold">${event.target.dataset.stateDescription}</span></span>`;
    if(['new', 'progress', 'ordered', 'shipped'].includes(state)) {
      msg += `<br><br><i class="fas fa-exclamation-circle"></i> ${I18n.t('purchase.confirm.reset_status_warehouse')}`
    }
    bootbox.dialog({
      message: `${I18n.t('purchase.confirm.reset_status')}<br><br>${msg}`,
      onEscape: true,
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        confirm: {
          label: I18n.t('btn.apply'),
          className: "btn-outline-danger",
          callback: function() {
            Rails.ajax({
              url: `/${that.locale}/purchase/${uuid}/state/${state}`,
              type: "post",
              data: "",
              success: function(data) {},
              error: function(data) {}
            });
            bootbox.hideAll();
          }
        }
      }
    });
  }

  cancelConfirm() {
    let that = this
    var uuid = event.target.dataset.uuid;
    var msg = `<span class="lead semi-bold">${event.target.dataset.purchaseref}</span><br>${event.target.dataset.supplier}`;
    bootbox.dialog({
      message: `${I18n.t('purchase.confirm.cancel')}<br><br>${msg}`,
      onEscape: true,
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        delete: {
          label: I18n.t('btn.cancel_purchase'),
          className: "btn-danger",
          callback: function() {
            Rails.ajax({
              url: `/${that.locale}/purchase/${uuid}/state/cancelled`,
              type: "post",
              data: "",
              success: function(data) {},
              error: function(data) {}
            });
            bootbox.hideAll();
          }
        }
      }
    });
  }

  resetOrderDate() {
    var e = event;
    setTimeout(() => {
      if(e.target.checked) {
        $("#datetimepicker1").datetimepicker('date', moment(e.target.dataset.date) );
      } else {
        $("#datetimepicker1").datetimepicker('date', moment() );
      }
    }, 100);
  }

  toggleWithVAT() {
    setTimeout(() => {
      let tot = document.querySelectorAll(".items-totals");
      let tot_w_vat = document.querySelectorAll(".items-totals-with-vat");
      if(document.querySelector("#swchWithVAT").checked) {
        for(let i = 0; i < tot.length; i++) {
          tot[i].classList.add('d-none');
          tot_w_vat[i].classList.remove('d-none');
        }
      } else {
        for(let i = 0; i < tot.length; i++) {
          tot_w_vat[i].classList.add('d-none');
          tot[i].classList.remove('d-none');
        }
      }
    }, 100);
  }

  newPurchaseFromExisting() {
    let that = this;
    let items = document.querySelectorAll(".card");
    items.forEach( (card) => {
      let item_select = card.querySelector("#item_uuid");
      let index = item_select.selectedIndex;

      let text = item_select.options[index].text;
      let uuid = item_select.options[index].value;
      let number = item_select.options[index].dataset.subtext.split('|')[0];

      $(item_select).selectpicker('refresh');
      card.querySelector("#item_label").classList.remove("d-none");
      card.querySelector("#item_label").parentElement.querySelector('label').innerText = text;
      if(number) {
        card.querySelector("#item_label").parentElement.querySelector('label').innerText += ` | ${number}`;
      }
      card.querySelector("#btn_item_open").classList.remove("d-none");
      card.querySelector("#btn_item_open").href = card.querySelector("#btn_item_open").href+uuid;

      var sp = $(".selectpicker.item").selectpicker({ width: '100%' });
      sp.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        that.itemSelected(e, clickedIndex);
      });

      var sp_vat = $(".selectpicker.vat").selectpicker({ width: '80px' });
      sp_vat.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        that.updatePrice();
      });

      item_select.parentElement.classList.add("d-none")
    })

    $(this.currencyTarget).selectpicker({ width: '250px'});
    //this.updateItemPrices(items, this.currencyTarget.value);
    this.updateTotal();
  }

  refreshItemNumbering() {
    this.item_positionTargets.forEach((lbl, i) => {
      lbl.innerText = i+1;
    });
  }

  refreshWarehouse(card) {
    let btn = card.querySelector("button[data-id='item_warehouse']");
    let warehouses = card.querySelector("#item_warehouse");
    let warehouse = warehouses.options[$(warehouses).prop('selectedIndex')];

    if(warehouse.style.color) {
      btn.style.color = warehouse.style.color;
    } else {
      btn.style.removeProperty('color');
    }
  }

  reloadPage() {
    setTimeout(() => {
      location.reload();
    }, 500);
  }

  setWarehouse(event) {
    let option = this.warehouseTarget.options[event.target.selectedIndex];
    document.querySelectorAll("div.card").forEach((card) => {
      let btn = card.querySelector("button[data-id='item_warehouse']");

      let sp_wh = card.querySelector("#item_warehouse");
      if(sp_wh.options.length > 0) {
        sp_wh.value = option.value;
        $(sp_wh).selectpicker('refresh');
      }

      if(option.dataset.color) {
        btn.style.color = option.dataset.color;
      } else {
        btn.style.removeProperty('color');
      }
    })
  }

  setDeliveredItems() {
    let uuid = this.element.getAttribute("data-uuid");

    let inp_delivery_date = document.querySelector("#delivery_date");
    if(!inp_delivery_date) {
      Rails.ajax({
        url: `/${this.locale}/purchase/${uuid}/received`,
        type: "get",
        data: "",
        success: function(data) {},
        error: function(data) {
          console.log("Error loading orders");
        }
      });
    }

  }

  selectAttachment() {
    var input = document.querySelector("#filepicker");
    input.click();
  }

  uploadAttachment() {
    let that = this;
    var input = event.target;
    const url = input.dataset.directUploadUrl;
    var files = input.files.length;
    let counter = parseInt(this.attachments_counterTarget.dataset.counter);

    Array.from(input.files).forEach(file => {
      const upload = new DirectUpload(file, url);
      that.attachment_uploadingTarget.classList.remove('d-none');

      upload.create((error, blob) => {
        if (error) {
          console.log(`ERROR: ${error}`);
        } else {
          Rails.ajax({
            url: `/${this.locale}/purchase/attachment/add/${btoa(blob.signed_id)}`,
            type: "get",
            data: "",
            success: function(data) {
              counter += 1;
              that.attachments_counterTarget.innerText = counter;
              that.attachments_counterTarget.dataset.counter = counter;

              that.attachment_uploadingTarget.classList.add('d-none');
            },
            error: function(data) {}
          });
        }
      })
    })

    input.value = '';
  }

  deleteAttachment() {
    var attachment = event.target.closest("div.d-flex");
    attachment.remove();
    this.attachments_counterTarget.dataset.counter = parseInt(this.attachments_counterTarget.dataset.counter - 1)
    this.attachments_counterTarget.innerText = this.attachments_counterTarget.dataset.counter;
  }

  switchTab() {
    let url;
    if(event.target.dataset.path) {
      url = location.origin+location.pathname+`?tab=${event.target.dataset.path}`;
    } else {
      url = location.origin+location.pathname;
    }
    window.history.replaceState({}, document.title, url);
  }

  initItemSelectDialog() {
    if(this.itemSelectDialog) {
      return
    }

    let that = this;
    let supplier = this.supplierTarget.value;

    this.itemSelectDialog = new bootstrap.Modal(document.querySelector('#itemSelectDialog'), {
      keyboard: true,
      focus: true
    });

    let table = $('#itemSelect').bootstrapTable({
      locale: this.locale,
      url: `/${this.locale}/purchases/items`,
      search: true,
      loadingFontSize: '1rem',
      classes: 'table',
      searchTimeOut: 250,
      clickToSelect: true,
      showColumns: true,
    });

    document.querySelector('#itemSelectDialog div.search').classList.add('w-75');
    document.querySelector('#itemSelectDialog input.search-input').style.height = '34px';

    that.warehouse_select = '';
    Rails.ajax({
      url: `/${that.locale}/warehouses`,
      type: "get",
      data: "",
      success: function(data) {
        that.warehouses = data;
        data.forEach((warehouse, i) => {
          that.warehouse_select += `<option value="${warehouse.uuid}" style="color: ${warehouse.data.color}">${warehouse.name}</option>`
        });
      },
      error: function(data) {
      }
    });

    table.on("column-switch.bs.table", function() {
      let cols = JSON.stringify(table.bootstrapTable('getVisibleColumns').map(function (it) {
        return it.field
      }))

      Rails.ajax({
        url: `/${that.locale}/subscription/config`,
        type: "post",
        data: `parameter=purchases_items_select_columns&value=${cols}`,
        success: function(data) {},
        error: function(data) {}
      });
    });


    let dialog = document.querySelector('#itemSelectDialog');
    let btnSelect = dialog.querySelector("button#btnSelect");

    btnSelect.addEventListener('click', function (event) {
      btnSelect.disabled = true;
      let icon = document.createElement("i");
      icon.classList.add("fas", "fa-spinner", "fa-pulse");
      btnSelect.prepend(icon);

      setTimeout(() => {
        let items = table.bootstrapTable('getSelections');
        if(items.length > 0) {
          that.addSelectedItems(items);
        }
      }, 50);

      setTimeout(() => {
        that.itemSelectDialog.hide();
        let icon2 = btnSelect.querySelector('svg');
        btnSelect.removeChild(icon2);
        btnSelect.disabled = false;

        dialog.querySelectorAll("button[data-loaded='true']").forEach((btn, i) => {
          let tab = btn.parentNode.querySelector('table');
          tab.innerHTML = '';
          btn.dataset.loaded = 'false';
        });

        table.bootstrapTable('uncheckAll');
        table.bootstrapTable('refresh', {silent: true});
      }, 250)
    });

    that.vat_select = '';
    Rails.ajax({
      url: `/${that.locale}/purchases/vats`,
      type: "get",
      data: "",
      success: function(data) {
        that.vat = data;
        Object.keys(data).forEach((country, i) => {
          that.vat_select += `<optgroup label="${I18n.t(`countries.${country}`)}">`;
          data[country].forEach((vat, i) => {
            that.vat_select += `<option value="${vat.value}">${vat.label}</option>`;
          });
          that.vat_select += "</optgroup>";
        });
      },
      error: function(data) {
      }
    });

    window.itemNameFormatter = function(name, item) {
      return `${name}&nbsp;&nbsp;<span class="small text-muted">${[item.size, item.color].filter(item => item).join(' | ')}</span>`
    }

    window.itemOrderNumberFormatter = function(number, item) {
      if(item.suppliers) {
        let sup = item.suppliers.filter(sup => sup.contact == supplier);
        if(sup.length > 0) {
          if(sup[0].order_number) {
            return sup[0].order_number;
          } else {
            return '';
          }
        } else {
          return '';
        }
      } else {
        return '';
      }
    }

    window.itemOrderQuantityFormatter = function(quantity, item) {
      if(item.suppliers) {
        let sup = item.suppliers.filter(sup => sup.contact == supplier);
        if(sup.length > 0) {
          if(sup[0].order_quantity) {
            return sup[0].order_quantity;
          } else {
            return '';
          }
        } else {
          return '';
        }
      } else {
        return '';
      }
    }

    window.itemStockFormatter = function(stock, item) {
      if(stock == null) {
        stock = '-'
      }
      if(item.with_stock) {
        return `
          <button type="button" class="btn btn-outline-light btn-sm text-dark" data-action="click->items#loadStockQuantities" data-uuid="${item.uuid}" data-loaded="false">
            ${stock}
          </button>
          <table class="small table-borderless table-sm warehouse-quantities"></table>
        `
      } else {
        return ''
      }
    }

    window.itemStatusFormatter = function(status, item) {
      let str = '';
      if(item.statusname) {
        if(item.status_subscription_id) {
          str += item.statusname;
        } else {
          str += I18n.t(`item.states.${item.statusname}`);
        }
      }
      return str
    }

    window.itemPriceFormatter = function(price, item) {
      if(item.suppliers) {
        let sup = item.suppliers.filter(sup => sup.contact == supplier);
        if(sup.length > 0) {
          if(sup[0].price) {
            return `${sup[0].price.toFixed(2)} ${I18n.t(`currencies.abbr.${sup[0].currency}`)}`;
          } else {
            return '';
          }
        } else {
          return '';
        }
      } else {
        return '';
      }
    }

    window.itemVatFormatter = function(vat, item) {
      if(item.suppliers) {
        let sup = item.suppliers.filter(sup => sup.contact == supplier);
        if(sup.length > 0) {
          if(sup[0].vat) {
            return `${sup[0].vat} %`;
          } else {
            return '';
          }
        } else {
          return '';
        }
      } else {
        return '';
      }
    }


    window.itemReservedFormatter = function(reserved, item) {
      if(item.with_stock && item.reserved && item.reserved > 0) {
        return reserved
      } else {
        return ''
      }
    }

    window.itemAvailabilityFormatter = function(available, item) {
      if(item.with_stock) {
        return item.availability
      } else {
        return ''
      }
    }
  }

  initData() {
    let table = $("#purchases");

    $.fn.bootstrapTable.locales[this.locale]['formatShowingRows'] = function (from, to, total) {
      return I18n.t('purchase.pagination', {from: from, to: to, total: total});
    }
    $.fn.bootstrapTable.locales[this.locale]['formatRecordsPerPage'] = function (count) {
      return I18n.t('purchase.per_page', {count: count});
    }

    table.bootstrapTable({
      url: `${window.location.href}?limit=500`,
      locale: this.locale,
      minHeight: '400',
      classes: 'table',
      search: true,
      pagination: true,
      paginationLoop: false,
      pageSize: 100,
      pageList: [50, 100, 250, 'All'],
      searchSelector: '#filter_search',
      searchText: this.filterTarget.value,
      searchTimeOut: 250,
      loadingFontSize: '1rem'
    });
    table.on('load-success.bs.table', () => {
      if(this.filterTarget.value != '') {
        this.filterTarget.focus();
        this.filterTarget.blur();
      }
      if(table.bootstrapTable('getData').length >= 500) {
        this.loadNextData(500);
      }
    });
  }

  loadNextData(offset) {
    let that = this;
    Rails.ajax({
      url: `${window.location.href}?limit=500&offset=${offset}`,
      type: "get",
      data: "",
      success: function(data) {
        $("#purchases").bootstrapTable('append', data)
        if(data.length == 500) {
          that.loadNextData(offset+500);
        }
      },
      error: function(data) {
        console.log("Error loading orders");
      }
    });
  }

  initTableFunctions() {
    I18n.locale = this.locale;
    let locale = this.locale;
    let purchasenext = I18n.t('purchase.states.progress');

    window.referenceFormatter = function(reference, row) {
      let link = `/${locale}/purchase/${row.uuid}`;
      return `<a href="${link}">${reference}</a>`
    }

    window.dateFormatter = function(date) {
      return I18n.l("date.formats.default", date);
    }

    window.stateFormatter = function(state) {
      switch(state) {
        case '10_new':
          purchasenext = I18n.t('purchase.states.progress');
          return `<span class="text-danger semi-bold">${I18n.t('purchase.states.new')}</span>`
          break;
        case '20_progress':
          purchasenext = I18n.t('purchase.states.ordered');
          return `<span class="text-primary semi-bold">${I18n.t('purchase.states.progress')}</span>`
          break;
        case '30_ordered':
          purchasenext = I18n.t('purchase.states.shipped');
          return `<span class="text-secondary semi-bold">${I18n.t('purchase.states.ordered')}</span>`
          break;
        case '40_shipped':
          purchasenext = I18n.t('purchase.states.received');
          return `<span class="text-secondary semi-bold">${I18n.t('purchase.states.shipped')}</span>`
          break;
        case '49_received_partial':
          purchasenext = I18n.t('purchase.states.received');
          return `<span class="text-success semi-bold">${I18n.t('purchase.states.received_partial')}</span>`
          break;
        case '50_received':
          purchasenext = I18n.t('purchase.states.completed');
          return `<span class="text-success semi-bold">${I18n.t('purchase.states.received')}</span>`
          break;
        case '60_completed':
          return `<span class="text-body semi-bold">${I18n.t('purchase.states.completed')}</span>`
          break;
        case '90_cancelled':
          return `<span class="text-black-50 semi-bold">${I18n.t('purchase.states.cancelled')}</span>`
          break;
      }
    }

    window.supplierFormatter = function (company, row) {
      let link = `/${locale}/purchase/${row.uuid}`;

      var subject = '';
      if(row.subject && row.subject != '') {
        subject = `
          <small class="text-muted">
            <br>${row.subject}
          </small>
        `;
      }
      return `<a href="${link}">${company}</a>${subject}`
    }

    window.actionsFormatter = function(data, row) {
      let reference = '';
      if(row.reference) {
        reference = row.reference;
      }

      let html = `
        <div class="btn-group">
          <button type="button" class="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown" data-bs-boundary="window" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-ellipsis-v"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-end">
          `
      if(row.state == '40_shipped') {
        html += `
          <h6 class="dropdown-header">${I18n.t('purchase.state')}</h6>
          <a class="dropdown-item" data-remote="true" href="/${locale}/purchase/${row.uuid}/received"><i class="fas fa-caret-right"></i> ${purchasenext}</a>
          <div class="dropdown-divider"></div>
        `
      }
      else if(row.state == '50_received') {
        html += `
          <h6 class="dropdown-header">${I18n.t('purchase.state')}</h6>
          <button type="button" class="dropdown-item" data-uuid="${row.uuid}" data-purchaseref="${row.purchaseref}" data-supplier="${row.address.split("\n")[0]}" data-action="click->purchases#completeConfirm"><i class="fas fa-caret-right"></i> ${purchasenext}</button>
          <div class="dropdown-divider"></div>
        `
      }
      else if(row.state != '60_completed' && row.state != '90_cancelled') {
        html += `
          <h6 class="dropdown-header">${I18n.t('purchase.state')}</h6>
          <a class="dropdown-item" data-remote="true" href="/${locale}/purchase/${row.uuid}/next"><i class="fas fa-caret-right"></i> ${purchasenext}</a>
          <div class="dropdown-divider"></div>
        `
      }
      html += `
          <h6 class="dropdown-header">${I18n.t('document.documents')}</h6>

          <a class="dropdown-item" target="_blank" href="/${locale}/purchase/${row.uuid}/order" data-action="click->purchases#reloadPage">${I18n.t('document.purchase_order')} <span class="semi-bold small">${reference}</span></a>
        </div>
      </div>
      <div class="btn-group" role="group">
        <a class="btn btn-outline-secondary" href="/${locale}/purchase/${row.uuid}/edit"><i class="fas fa-edit"></i></a>

        <button type="button" class="btn btn-outline-danger" data-uuid="${row.uuid}" data-purchaseref="${row.purchaseref}" data-supplier="${row.company}" data-action="click->purchases#deleteConfirm"><i class="fas fa-trash"></i></button>
      </div>
    `

      return html;
    }
  }
}
