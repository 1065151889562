import { Controller } from "stimulus"
import { TempusDominus } from "@eonasdan/tempus-dominus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {

  static targets = [ "toast", "address", "address_select", "address_select_div", "currency", "currencies", "net", "gross", "net_base", "gross_base", "vat", "debit", "credit", "exchange_rate", "exchange_rate_div", "amount_base_currency", "account", "swtchUnusedAccounts", "basic", "advanced", "swtchAdvanced", "income", "expense", "filter", "subfilter", "header", "section_payment", "entries", "dtstart", "dtend", "futureEntriesToggle", "entries_incomes", "entries_expenses", "entries_balance", "attachment_uploading", "chartSummary", "chartSummaryForecast", "chartEntries", "edit_account_number", "edit_account_subnumber", "advDebitTotal", "advCreditTotal", "advDebitAccounts", "advCreditAccounts", "advTemplates"]

  static values = {
    currency: String,
    currencyFormat: String,
    currencyAbbr: String
  }

  initialize() {
    if(this.element.getAttribute("data-locale")) {
      this.locale = this.element.getAttribute("data-locale");
    }

    if(this.element.getAttribute("data-vat-label")) {
      this.vat_label = this.element.getAttribute("data-vat-label");
    }

    if(this.element.getAttribute("data-entry")) {
      this.entry = this.element.getAttribute("data-entry");
    }

    if(this.element.getAttribute("data-page")) {
      this.page = this.element.getAttribute("data-page");
    }
    if(this.element.getAttribute("data-view")) {
      this.view = this.element.getAttribute("data-view");
    }

    if(this.element.getAttribute("data-account")) {
      this.account = this.element.getAttribute("data-account");
    }

    if(this.element.getAttribute("data-currency")) {
      this.currency = this.element.getAttribute("data-currency");
    }

    if(this.element.getAttribute("data-dtstart")) {
      this.dtstart = this.element.getAttribute("data-dtstart");
    }
    if(this.element.getAttribute("data-dtend")) {
      this.dtend = this.element.getAttribute("data-dtend");
    }
  }

  connect() {
    if(this.page == 'index') {
      this.summaryChart();
    }

    if(this.page == 'entries' || this.page == 'account') {
      this.initTableFunctions();
      this.initData();

      if(this.hasAccountTarget) {
        this.accountTarget.value = this.account;
        let sp_account = $("#account.selectpicker.account").selectpicker({ width: '100%'});
        sp_account.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {

        });
      }
    }

    if(this.page == 'accounts') {
      let filter;
      filter = Cookies.get('filter_accounting_accounts');

      if(filter && this.hasFilterTarget) {
        var eku = new Event('keyup');

        this.filterTarget.value = filter;

        setTimeout(() => {
          this.subfilterTargets.forEach((sf, i) => {
            sf.value = filter;
            sf.dispatchEvent(eku)
          })
        }, 250)
      }

      let chk = Cookies.get(`accounting_hide_unused`)
      if(chk == 'true') {
        this.swtchUnusedAccountsTarget.checked = true;
      }
    }

    if(this.page == 'new' || this.page == 'edit') {
      let that = this;

      if(this.hasVatTarget) {
        if(this.vatTarget.dataset.vat) {
          this.vatTarget.value = this.vatTarget.dataset.vat;
        } else {
          this.vatTarget.value = '0.0';
        }
        let sp_vat = $(this.vatTarget).selectpicker({ width: '100px'});

        sp_vat.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
          that.changeVAT();
        });

        let sel = document.querySelector("#data_contact")
        if(sel.dataset.selected) {
          sel.value = sel.dataset.selected;
        }
        $(sel).selectpicker({ width: '100%', maxWidth: '500px'});
      }

      if(this.hasDebitTarget) {
        let account_debit = this.debitTarget.querySelector("select");
        account_debit.value = account_debit.dataset.account;
      }
      if(this.hasCreditTarget) {
        let account_credit = this.creditTarget.querySelector("select");
        account_credit.value = account_credit.dataset.account;
      }

      let sp_a = $('.selectpicker.accounts').selectpicker({width: '100%'});

      if(this.currencyTarget.dataset.currency) {
        this.currencyTarget.value = this.currencyTarget.dataset.currency;
      }

      sp_a.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        that.currencyTarget.value = e.target.options[clickedIndex].dataset.currency;
        that.changeCurrency(null, e.target.options[clickedIndex].dataset.currency);
      });
   }

   if(this.page == 'book_invoice') {
     document.querySelectorAll(".selectpicker.accounts").forEach((sel, i) => {
       sel.value = sel.dataset.account;
     });

     $(".selectpicker.accounts").selectpicker({width: '100%'});
   }

   let accountingController = this;

    if(document.querySelector('#datetimepicker1')) {
      new TempusDominus(document.getElementById('datetimepicker1'), {
        localization: {
          locale: this.locale,
          startOfTheWeek: 1
        },
        useCurrent: true,
        display: {
          components: {
            date: true,
            decades: true,
            month: true,
            year: true,
            hours: false,
            seconds: false,
            minutes: false
          },
          buttons: {
            today: true
          }
        }
      });
    }

    if(document.querySelector('#datetimepicker2')) {
      let controller = this;

      const dtpicker1 = new TempusDominus(document.getElementById('datetimepicker2'), {
        localization: {
          locale: this.locale,
          startOfTheWeek: 1
        },
        useCurrent: true,
        display: {
          components: {
            date: true,
            decades: true,
            month: true,
            year: true,
            hours: false,
            seconds: false,
            minutes: false,
          },
          buttons: {
            today: true
          }
        }
      });

      const dtpicker2 = new TempusDominus(document.getElementById('datetimepicker3'), {
        localization: {
          locale: this.locale,
          startOfTheWeek: 1
        },
        useCurrent: false,
        display: {
          components: {
            date: true,
            decades: true,
            month: true,
            year: true,
            hours: false,
            seconds: false,
            minutes: false,
          },
          buttons: {
            today: true
          }
        }
      });

      $('#datetimepicker2').on('change.td', (e) => {
        let dt = moment(e.date);
        controller.dtstartTarget.value = dt.format('YYYY-MM-DD');

        dtpicker2.updateOptions({
          restrictions: {
            minDate: e.date
          }
        });
      });
      $('#datetimepicker2').on('hide.td', (e) => {
        let dt = moment(e.date);
        controller.dtstartTarget.value = dt.format('YYYY-MM-DD');

        dtpicker2.updateOptions({
          restrictions: {
            minDate: e.date
          }
        });
      });

      $('#datetimepicker3').on('change.td', (e) => {
        let dt = moment(e.date);
        controller.dtendTarget.value = dt.format('YYYY-MM-DD');

        dtpicker1.updateOptions({
          restrictions: {
            maxDate: e.date
          }
        });
      });
      $('#datetimepicker3').on('hide.td', (e) => {
        let dt = moment(e.date);
        controller.dtendTarget.value = dt.format('YYYY-MM-DD');
        dtpicker1.updateOptions({
          restrictions: {
            maxDate: e.date
          }
        });
      });
    }

    $('.toast').on('hidden.bs.toast', function () {
      accountingController.toastTarget.style.display = 'none';
    })

    var sp_account = $("#entry_account").selectpicker({ width: '100%'});
    sp_account.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
      accountingController.changeAccount(e, clickedIndex);
    });

    var sp_contact = $("#entry_contact").selectpicker({ width: '100%'});
    sp_contact.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
      accountingController.contactSelected(e);
    });

/*
    let sp_currency = $('.selectpicker.currency').selectpicker({ width: '220px'});
    sp_currency.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
      if(!clickedIndex) {
        clickedIndex = accountingController.currencyTarget.selectedIndex;
      }
      accountingController.changeCurrency(e.target.value);
    });
    */

    //Settings page
    $('.selectpicker.currencies').selectpicker({ width: '180px'})

    if(this.hasAddress_selectTarget) {
      $(this.address_selectTarget).selectpicker().on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        accountingController.setAlternateCustomerAddress(e.target.options[clickedIndex].dataset);
      });
    }

    $('#entry_category').selectpicker({ width: '100%'});

    if(this.entry && this.entry != '') {
      //this.loadEntry()
    }

    if(document.querySelector('#datetimepicker')) {
      const dtpicker = new TempusDominus(document.getElementById('datetimepicker'), {
        localization: {
          locale: this.locale,
          startOfTheWeek: 1
        },
        useCurrent: true,
        display: {
          components: {
            date: false,
            decades: true,
            month: true,
            year: true,
            hours: false,
            seconds: false,
            minutes: false,
          },
          buttons: {
            today: true
          }
        }
      });

      $('#datetimepicker').on('change.td', (e) => {
        let dt = moment(e.date);
        window.location.href = `/${accountingController.locale}/accounting/${dt.year()}/${dt.month()+1}`
      });
      $('#datetimepicker').on('hide.td', (e) => {
        let dt = moment(e.date);
        window.location.href = `/${accountingController.locale}/accounting/${dt.year()}/${dt.month()+1}`
      });
    }

    let edit_account_parent = document.querySelector('#account_parent');
    if(edit_account_parent) {
      var accountType = document.querySelector("#accounttype2");
      if(accountType.checked) {
        accountType.click();
      }

      let sel_account = document.querySelector('#account_account');
      let inp_number = document.querySelector('#account_number');
      let inp_subnumber = document.querySelector("#account_subnumber");
      let sel_currency = document.querySelector('#account_currency');

      let eap = $(edit_account_parent).selectpicker({width: '400px'});
      eap.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        sel_account.value = e.target.options[clickedIndex].dataset.account;
        inp_number.value = e.target.options[clickedIndex].dataset.number;
        sel_currency.value = e.target.options[clickedIndex].dataset.currency;
        inp_subnumber.value = parseInt(e.target.options[clickedIndex].dataset.count)+1;
      });
    }

    if(this.hasSwtchAdvancedTarget && this.swtchAdvancedTarget.checked) {
      this.toggleAdvanced();
      let divsD = this.advDebitAccountsTarget.querySelectorAll('select');
      let divsC = this.advCreditAccountsTarget.querySelectorAll('select');
      divsD.forEach((sel, i) => {
        sel.value = sel.dataset.account;
      });
      divsC.forEach((sel, i) => {
        sel.value = sel.dataset.account;
      });
      $(".selectpicker").selectpicker("refresh")
    }

    $('#loading').fadeOut(200);
  }

  filterAccounts() {
    var eku = new Event('drop');
    this.subfilterTargets.forEach((sf, i) => {
      sf.value = event.target.value;
      sf.dispatchEvent(eku)
    });

    Cookies.set('filter_accounting_accounts', this.filterTarget.value.toLowerCase());
  }

  clearFilter() {
    this.filterTarget.value = '';

    Cookies.expire('filter_accounting_accounts');

    $("table.accounts").bootstrapTable('resetSearch', '');
    this.filterTarget.focus();
    this.filterTarget.blur();
  }

  currenciesAbbr(currency) {
    if(this.currencyAbbrValue == 'iso4217') {
      return currency.toUpperCase();
    } else {
      return I18n.t(`currencies.abbr.${currency}`);
    }
  }

  currenciesFormat(value, currency) {
    if(this.currencyFormatValue == '%u %n') {
      return `${this.currenciesAbbr(currency)} ${value}`
    } else {
      return `${value} ${this.currenciesAbbr(currency)}`
    }
  }

  summaryChart() {
    if(this.hasChartSummaryTarget) {
      let incomes = this.chartSummaryTarget.dataset.incomes;
      let expenses = this.chartSummaryTarget.dataset.expenses;
      let currency = this.chartSummaryTarget.dataset.currency;
      let maxValue = incomes + 100;
      if(parseFloat(expenses) > parseFloat(incomes)) {
        maxValue = parseFloat(expenses) + 100;
      }

      const chart1 = new Chart(this.chartSummaryTarget, {
        type: 'bar',
        data: {
          labels: [I18n.t('accounting.incomes'), I18n.t('accounting.expenses')],
          datasets: [{
            label: '',
            data: [incomes, expenses],
            backgroundColor: [
              'rgba(75, 192, 192, 0.2)',
              'rgba(255, 99, 132, 0.2)'
            ],
            borderColor: [
              'rgb(75, 192, 192)',
              'rgba(255, 99, 132, 1)',
            ],
            borderWidth: 1
          }]
        },
        options: {
          scales: {
            y: {
              ticks: {
                callback: function(value, index, values) {
                  return value.toFixed(2) + ' ' + currency;
                }
              },
              suggestedMax: maxValue,
              beginAtZero: true
            },
          },
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              callbacks: {
                title: function(tooltipItem, d) {
                  return tooltipItem[0].label;
                },
                label: function(tooltipItem, d) {
                  return ' '+tooltipItem.formattedValue + ' ' + currency;
                },
              }
            }
          }
        }
      });

      let incomesForecast = this.chartSummaryForecastTarget.dataset.incomes;
      let expensesForecast = this.chartSummaryForecastTarget.dataset.expenses;
      let maxValueForecast = parseFloat(incomesForecast) + 100;
      if(parseFloat(expensesForecast) > parseFloat(incomesForecast)) {
        maxValueForecast = parseFloat(expensesForecast) + 100;
      }

      const chart2 = new Chart(this.chartSummaryForecastTarget, {
        type: 'bar',
        data: {
          labels: [I18n.t('accounting.incomes'), I18n.t('accounting.expenses')],
          datasets: [{
            label: '',
            data: [incomesForecast, expensesForecast],
            backgroundColor: [
              'rgba(75, 192, 192, 0.2)',
              'rgba(255, 99, 132, 0.2)'
            ],
            borderColor: [
              'rgb(75, 192, 192)',
              'rgba(255, 99, 132, 1)',
            ],
            borderWidth: 1
          }]
        },
        options: {
          scales: {
            y: {
              ticks: {
                callback: function(value, index, values) {
                  return value.toFixed(2) + ' ' + currency;
                }
              },
              suggestedMax: maxValueForecast,
              beginAtZero: true
            },
          },
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              callbacks: {
                title: function(tooltipItem, d) {
                  return tooltipItem[0].label;
                },
                label: function(tooltipItem, d) {
                  return ' '+tooltipItem.formattedValue + ' ' + currency;
                },
              }
            }
          }
        }
      });
    }
  }

  chartEntries(future = false) {
    function custom_sort(a, b) {
      return moment(a.timestamp) > moment(b.timestamp);
    }

    if(this.hasChartEntriesTarget) {
      let data = $("#entries").bootstrapTable('getData');

      let d = [];
      let f = [];
      let dt;
      let dtstr;
      let tomorrow = moment().add(1, 'days');
      let day = this.dtstartTarget.value;

      if(data.length > 0) {
        data = data.filter(function(d) { return d.done == true; });
        data = data.reverse();

        if(data.length > 0) {
          d = [{x: this.dtstartTarget.value, y: data[0].balance}];
          let balance = data[0].balance;

          for(let i = 0;i < data.length; i++) {
            dt = moment(data[i].timestamp);
            dtstr = dt.format('YYYY-MM-DD');
            d.push({x: dtstr, y: data[i].balance})
          }
        }

        if(future == false) {
          if(data.length > 0) {
            d.push({x: this.dtendTarget.value, y: data[data.length-1].balance})
          }
        } else {
          if(data.length > 0) {
            f.push(d[d.length - 1]);
          }
          let future = $("#entries").bootstrapTable('getData');
          future = future.filter(function(dd) { return dd.done == false; });
          future = future.reverse();

          if(future.length > 0) {
            for(let i = 0;i < future.length; i++) {
              dt = moment(future[i].timestamp);
              if(dt.isBefore(tomorrow)) {
                dt = tomorrow;
              }
              dtstr = dt.format('YYYY-MM-DD');
              f.push({x: dtstr, y: future[i].balance})
            }
            f.push({x: this.dtendTarget.value, y: future[future.length-1].balance})
          }
        }
      }

      let dtstart = this.dtstartTarget.value;
      let dtend = this.dtendTarget.value;
      let currency = currenciesAbbr(this.currency);
      const chart1 = new Chart(this.chartEntriesTarget, {
        type: 'line',
        data: {
          datasets: [{
            data: d,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          }, {
            data: f,
            fill: false,
            borderColor: 'rgb(255, 205, 86)',
            tension: 0.1
          }]
        },
        options: {
          scales: {
            x: {
              min: moment(dtstart),
              max: moment(dtend),
              type: 'time',
              time: {
                unit: 'day'
              }
            },
            y: {
              ticks: {
                callback: function(value, index, values) {
                  return value.toFixed(2) + ' ' + currency;
                }
              },
            }
          },
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              callbacks: {
                title: function(tooltipItem, d) {
                  return tooltipItem[0].label;
                },
                label: function(tooltipItem, d) {
                  return ' '+tooltipItem.formattedValue + ' ' + currency;
                },
              }
            }
          }
        }
      });
    }
  }

  loadEntry() {

    let address_addressline1 = document.querySelector('#address_addressline1');
    let address_addressline2 = document.querySelector('#address_addressline2');
    let address_name = document.querySelector('#address_name');
    let address_street = document.querySelector('#address_street');
    let address_zip = document.querySelector('#address_zip');
    let address_location = document.querySelector('#address_location');
    let address_state = document.querySelector('#address_state');
    let address_country = document.querySelector('#address_country');

    let address_taxid = document.querySelector('#address_taxid');
    let address_fiscal_code = document.querySelector('#address_fiscal_code');
    let address_unique_code = document.querySelector('#address_unique_code');
    let address_cem = document.querySelector('#address_cem');

    let addr = '';

    if(address_addressline1.value != '') {
      addr += address_addressline1.value+"\n";
    }
    if(address_addressline2.value != '') {
      addr += address_addressline2.value+"\n";
    }
    addr += address_name.value+"\n";
    addr += address_street.value+"\n";
    addr += `${address_zip.value} ${address_location.value}`;

    if(address_state.value != '') {
      addr += "\n"+address_state.value;
    }
    if(address_country.value != '') {
      addr += "\n"+countries.getName(address_country.value, this.locale);
    }

    addr += "\n"
    if(address_taxid.value != '') {
      addr += `\n${this.vat_label}: ${address_taxid.value}`;
    }
    if(address_fiscal_code.value != '') {
      addr += `\n${I18n.t('contact.fiscal_code')}: ${address_fiscal_code.value}`;
    }
    if(address_unique_code.value != '') {
      addr += `\n${I18n.t('contact.unique_code')}: ${address_unique_code.value}`;
    }
    if(address_cem.value != '') {
      addr += `\n${I18n.t('contact.cem')}: ${address_cem.value}`;
    }

    this.addressTarget.value = addr.trim();
  }

  copyIBAN() {
    let iban = event.target.dataset.iban;
    if(iban) {
      navigator.clipboard.writeText(iban);
      business.showToast(I18n.t('accounting.notifications.iban_copied_clipboard'));
    }
    event.target.blur();
  }

  contactSelected(e) {
    let uuid = e.target.value;
    let accountingController = this;

    //Get contact details
    Rails.ajax({
      url: `/${this.locale}/contact/${uuid}.json`,
      type: "get",
      data: "",
      success: function(data) {
        accountingController.setContactAddress(data);
      },
      error: function(data) {}
    });
  }

  setContactAddress(data) {
    let addr = '';
    if(data.company.trim() != '') {
      address_name.value = data.company;
    } else {
      if(data.firstname != '' || data.lastname != '') {
        address_name.value = (`${data.firstname} ${data.lastname}`).trim();
      }
    }

    if(data.addresses.length > 0) {
      //If addrelines are set, do not set the company name
      if((data.addresses[0].addressline1 && data.addresses[0].addressline1 != '') || (data.addresses[0].addressline2 && data.addresses[0].addressline2 != '')) {
        if(data.addresses[0].addressline1 && data.addresses[0].addressline1 != '') {
          addr += data.addresses[0].addressline1+"\n";
          address_addressline1.value = data.addresses[0].addressline1;
        } else {
          address_addressline1.value = '';
        }
        if(data.addresses[0].addressline2 && data.addresses[0].addressline2 != '') {
          addr += data.addresses[0].addressline2+"\n";
          address_addressline2.value = data.addresses[0].addressline2;
        } else {
          address_addressline2.value = '';
        }

        address_name.value = '';
      } else {
        addr += address_name.value+"\n";

        address_addressline1.value = '';
        address_addressline2.value = '';
      }

      addr += data.addresses[0].street+"\n";

      if(data.addresses[0].country && data.addresses[0].country != '') {
        addr += `${data.addresses[0].country}-${data.addresses[0].zip} ${data.addresses[0].location}`;
      } else {
        addr += `${data.addresses[0].zip} ${data.addresses[0].location}`;
      }

      address_street.value = data.addresses[0].street;
      address_zip.value = data.addresses[0].zip;
      address_location.value = data.addresses[0].location;

      if(data.addresses[0].state) {
        //addr += "\n"+data.addresses[0].state;
        address_state.value = data.addresses[0].state;
      } else {
        address_state.value = '';
      }
      if(data.addresses[0].country) {
        //addr += "\n"+countries.getName(data.addresses[0].country, this.locale);
        address_country.value = data.addresses[0].country;
      } else {
        address_country.value = '';
      }
    }

    address_taxid.value = '';
    address_fiscal_code.value = '';
    address_unique_code.value = '';
    address_cem.value = '';
    if(data.taxid != '' && data.taxid != null) {
      addr += `\n\n${this.vat_label}: ${data.taxid}`;

      address_taxid.value = data.taxid;
    }
    if(data.data && data.data.fiscal_code && data.data.fiscal_code != '') {
      addr += `\n${this.fiscal_code}: ${data.data.fiscal_code}`;

      address_fiscal_code.value = data.data.fiscal_code;
    }
    if(data.data && data.data.unique_code && data.data.unique_code != '') {
      addr += `\n${this.unique_code}: ${data.data.unique_code}`;

      address_unique_code.value = data.data.unique_code;
    }

    if(data.emails.length > 0) {
      data.emails.forEach((email) => {
        if(email.email_type == 'cem') {
          addr += `\n${this.cem}: ${email.email_address}`;

          address_cem.value = email.email_address;
        }
      })
    }

    this.addressTarget.value = addr;

    // Set alternate addresses if present
    this.addAlternateAddresses(data);

  }

  addAlternateAddresses(data) {

    this.address_selectTarget.innerHTML = '';

    if(data.addresses.length > 1) {
      for(let i = 0; i < data.addresses.length; i++) {

        let str = [];

        //If addrelines are set, do not set the company name
        if((data.addresses[i].addressline1 && data.addresses[i].addressline1 != '') || (data.addresses[i].addressline2 && data.addresses[i].addressline2 != '')) {
          if(data.addresses[i].addressline1 && data.addresses[i].addressline1 != '') {
            str.push(data.addresses[i].addressline1);
          }
          if(data.addresses[i].addressline2 && data.addresses[i].addressline2 != '') {
            str.push(data.addresses[i].addressline2);
          }
        } else {
          if(data.company.trim() != '') {
            str.push(data.company);
          } else {
            if(data.firstname != '' || data.lastname != '') {
              str.push((`${data.firstname} ${data.lastname}`).trim());
            }
          }
        }

        str.push(data.addresses[i].street);
        str.push(data.addresses[i].zip);
        str.push(data.addresses[i].location);
        if(data.addresses[i].state) {
          str.push(data.addresses[i].state);
        }
        if(data.addresses[i].country) {
          str.push(countries.getName(data.addresses[i].country, this.locale));
        }

        let option = document.createElement("option");
        option.text = str.join(', ');
        option.dataset.subtext = data.addresses[i].label;
        this.setAddressDataFields(option, data, data.addresses[i]);
        this.address_selectTarget.appendChild(option);
      }

      $(this.address_selectTarget).selectpicker('refresh');
      this.address_select_divTargets.forEach((div) => {
        div.classList.remove("d-none");
      })
    } else {
      this.address_select_divTargets.forEach((div) => {
        div.classList.add("d-none");
      })

      $(this.address_selectTarget).selectpicker('refresh');
    }
  }

  setAddressDataFields(target, data, address) {

    if(address.addressline1) {
      target.dataset.addressline1 = address.addressline1;
    }
    if(address.addressline2) {
      target.dataset.addressline2 = address.addressline2;
    }

    if(data.company.trim() != '') {
      target.dataset.name = data.company;
    } else {
      if(data.firstname != '' || data.lastname != '') {
        target.dataset.name = (`${data.firstname} ${data.lastname}`).trim();
      }
    }

    target.dataset.street = address.street;
    target.dataset.zip = address.zip;
    target.dataset.location = address.location;
    target.dataset.state = address.state;
    if(address.country) {
      target.dataset.country = countries.getName(address.country, this.locale);
      target.dataset.country_code = address.country;
    }

    if(data.taxid != '' && data.taxid != null) {
      target.dataset.vat = `${this.vat_label}: ${data.taxid}`;
    }
    if(data.data && data.data.fiscal_code && data.data.fiscal_code != '') {
      target.dataset.fiscal_code = `${this.fiscal_code}: ${data.data.fiscal_code}`;
    }
    if(data.data && data.data.unique_code && data.data.unique_code != '') {
      target.dataset.unique_code = `${this.unique_code}: ${data.data.unique_code}`;
    }

    if(data.emails.length > 0) {
      data.emails.forEach((email) => {
        if(email.email_type == 'cem') {
          target.dataset.cem = `${this.cem}: ${email.email_address}`;
        }
      })
    }
  }

  setAlternateCustomerAddress(data) {
    let address_addressline1 = document.querySelector('#address_addressline1');
    let address_addressline2 = document.querySelector('#address_addressline2');
    let address_name = document.querySelector('#address_name');
    let address_street = document.querySelector('#address_street');
    let address_zip = document.querySelector('#address_zip');
    let address_location = document.querySelector('#address_location');
    let address_state = document.querySelector('#address_state');
    let address_country = document.querySelector('#address_country');

    let address_taxid = document.querySelector('#address_taxid');
    let address_fiscal_code = document.querySelector('#address_fiscal_code');
    let address_unique_code = document.querySelector('#address_unique_code');
    let address_cem = document.querySelector('#address_cem');

    address_addressline1.value = '';
    address_addressline2.value = '';
    address_name.value = '';
    address_street.value = '';
    address_zip.value = '';
    address_location.value = '';
    address_state.value = '';
    address_country.value = '';

    let str = [];

    if(data.addressline1) {
      address_addressline1.value = data.addressline1;
      str.push(data.addressline1);
    }
    if(data.addressline2) {
      address_addressline2.value = data.addressline2;
      str.push(data.addressline2);
    }
    if(data.name) {
      address_name.value = data.name;
      str.push(data.name);
    }

    address_street.value = data.street;
    address_zip.value = data.zip;
    address_location.value = data.location;

    str.push(data.street);

    if(data.country_code && data.country_code != '') {
      str.push(`${data.country_code}-${data.zip} ${data.location}`.trim());
    } else {
      str.push(`${data.zip} ${data.location}`.trim());
    }

    if(data.state) {
      //str.push(data.state);
      address_state.value = data.state;
    }
    if(data.country) {
      //str.push(data.country);
      address_country.value = data.country;
    }

    str.push('');

    if(address_taxid.value != '') {
      str.push(`${this.vat_label}: ${address_taxid.value}`);
    }
    if(address_fiscal_code.value != '') {
      str.push(`${this.fiscal_code}: ${address_fiscal_code.value}`);
    }
    if(address_unique_code.value != '') {
      str.push(`${this.unique_code}: ${address_unique_code.value}`);
    }
    if(address_cem.value) {
      str.push(`${this.cem}: ${address_cem.value}`);
    }

    this.addressTarget.value = str.join('\n').trim();
  }

  editAddressDELETE() {
    let address_addressline1;
    let address_addressline2;
    let address_name;
    let address_street;
    let address_zip;
    let address_location;
    let address_state;
    let address_country;

    //Hidden fields
    address_addressline1 = document.querySelector('#address_addressline1');
    address_addressline2 = document.querySelector('#address_addressline2');
    address_name = document.querySelector('#address_name');
    address_street = document.querySelector('#address_street');
    address_zip = document.querySelector('#address_zip');
    address_location = document.querySelector('#address_location');
    address_state = document.querySelector('#address_state');
    address_country = document.querySelector('#address_country');

    let address_taxid = document.querySelector('#address_taxid');
    let address_fiscal_code = document.querySelector('#address_fiscal_code');
    let address_unique_code = document.querySelector('#address_unique_code');
    let address_cem = document.querySelector('#address_cem');

    let str = `target=${event.target.id}`;
    if(address_addressline1.value != '') {
      str += `&addressline1=${address_addressline1.value}`
    }
    if(address_addressline2.value != '') {
      str += `&addressline2=${address_addressline2.value}`
    }
    if(address_name.value != '') {
      str += `&name=${address_name.value}`
    }
    str += `&street=${address_street.value}`
    str += `&zip=${address_zip.value}`
    str += `&city=${address_location.value}`
    str += `&state=${address_state.value}`
    str += `&country=${address_country.value}`

    if(address_taxid.value != '') {
      str += `&vat=${address_taxid.value}`
    }
    if(address_fiscal_code.value != '') {
      str += `&fiscal_code=${address_fiscal_code.value}`
    }
    if(address_unique_code.value != '') {
      str += `&unique_code=${address_unique_code.value}`
    }
    if(address_cem.value != '') {
      str += `&cem=${address_cem.value}`
    }

    //Get contact details
    Rails.ajax({
      url: `/${this.locale}/accounts/0/address/edit`,
      type: "get",
      data: str,
      success: function(data) {},
      error: function(data) {}
    });
  }

  changeAccount(event, index) {
    let option = event.target.options[index];

    if(option.dataset.currency) {
      this.currencyTarget.value = option.dataset.currency;
      $(this.currencyTarget).selectpicker('refresh');

      this.currenciesTargets.forEach((lbl, i) => {
        lbl.innerHTML = currenciesAbbr(option.dataset.currency);
      });
    }
  }

  changeCurrency(event, currency = null) {
    let that = this;
    if(event) {
      currency = event.target.value;
    }

    this.currenciesTargets.forEach((lbl, i) => {
      lbl.innerHTML = this.currenciesAbbr(currency);
    });

    let base = this.currencyTarget.options[0].value
    if(base != currency) {
      Rails.ajax({
        url: `/utils/exchangerate/${base}/${currency}`,
        type: "get",
        data: "",
        success: function(data) {
          if(data && data.exchangerate) {
            let inp = that.exchange_rate_divTarget.querySelector('input');
            inp.value = `1 ${that.currenciesAbbr(base)} = ${data.exchangerate.toFixed(3)} ${that.currenciesAbbr(currency)}`;
            that.exchange_rate_divTarget.classList.remove('d-none');

            that.amount_base_currencyTarget.classList.remove('d-none');
            that.net_baseTarget.value = (parseFloat(that.netTarget.value)/data.exchangerate.toFixed(3)).toFixed(2);
            that.gross_baseTarget.value = (parseFloat(that.grossTarget.value)/data.exchangerate.toFixed(3)).toFixed(2);

            that.exchange_rateTarget.value = data.exchangerate.toFixed(3);
          }
        },
        error: function(data) {}
      });
    } else {
      that.amount_base_currencyTarget.classList.add('d-none');
      that.net_baseTarget.value = '';
      that.gross_baseTarget.value = '';

      this.exchange_rate_divTarget.classList.add('d-none');
      let inp = this.exchange_rate_divTarget.querySelector('input');
      inp.value = '-';

      this.exchange_rateTarget.value = '';
    }
  }

  changeVAT() {
    let vat = this.vatTarget.value;
    if(vat > 0) {
      this.grossTarget.removeAttribute('readonly');
      this.grossTarget.classList.remove('bg-light');
    } else {
      this.grossTarget.setAttribute('readonly', true);
      this.grossTarget.classList.add('bg-light');
    }
    this.updateGross();
  }

  updateGross() {
    let amount = parseFloat(this.netTarget.value);
    let vat = parseFloat(this.vatTarget.value);
    this.grossTarget.value = (amount*(1+vat/100)).toFixed(2);

    if(this.exchange_rateTarget.value != '') {
      this.net_baseTarget.value = (parseFloat(this.netTarget.value)/parseFloat(this.exchange_rateTarget.value)).toFixed(2);

      this.gross_baseTarget.value = (parseFloat(this.grossTarget.value)/parseFloat(this.exchange_rateTarget.value)).toFixed(2);
    }
  }

  updateNet() {
    let amount = parseFloat(this.grossTarget.value);
    let vat = parseFloat(this.vatTarget.value);
    this.netTarget.value = (amount/(1+vat/100)).toFixed(2);

    if(this.exchange_rateTarget.value != '') {
      this.net_baseTarget.value = (parseFloat(this.netTarget.value)/parseFloat(this.exchange_rateTarget.value)).toFixed(2);

      this.gross_baseTarget.value = (parseFloat(this.grossTarget.value)/parseFloat(this.exchange_rateTarget.value)).toFixed(2);
    }
  }

  checkForm(event) {
    if(this.addressTarget.value.trim() == '') {
      this.addressTarget.classList.add('border-danger');
    } else {
      this.addressTarget.classList.remove('border-danger');
    }
  }

  setEntryType() {
    document.querySelector("#entry_type").value = event.target.dataset.type;
    this.incomeTarget.classList = 'btn btn-outline-secondary';
    this.expenseTarget.classList = 'btn btn-outline-secondary';
    switch(event.target.dataset.type) {
      case 'income':
        this.incomeTarget.classList.remove('btn-outline-secondary');
        this.incomeTarget.classList.add('btn-secondary');
        this.headerTarget.innerHTML = I18n.t('accounting.income_new');
        break;
      case 'expense':
        this.expenseTarget.classList.remove('btn-outline-secondary');
        this.expenseTarget.classList.add('btn-secondary');
        this.headerTarget.innerHTML = I18n.t('accounting.expense_new');
        break;
    }
    event.target.blur();
  }

  toggleAccounts() {
    let balance;
    Cookies.set(`accounting_hide_unused`, event.target.checked);
    document.querySelectorAll('table.accounts').forEach((table, i) => {
      table.rows.forEach((row, i) => {
        if(row.dataset.balance) {
          if(parseFloat(row.dataset.balance) == 0 && event.target.checked) {
            row.classList.add('d-none');
          } else {
            row.classList.remove('d-none');
          }
        }
      });
    });
  }

  selectAttachment() {
    var input = document.querySelector("#filepicker");
    input.click();
  }

  uploadAttachment() {
    let accountingController = this;
    var input = event.target;
    const url = input.dataset.directUploadUrl;
    var files = document.querySelector("div#attachments");

    var files = input.files.length;
    var count = 0;

    Array.from(input.files).forEach(file => {
      const upload = new DirectUpload(file, url);
      accountingController.attachment_uploadingTarget.classList.remove('d-none');

      upload.create((error, blob) => {
        if (error) {
          console.log(`ERROR: ${error}`);
        } else {
          Rails.ajax({
            url: `/${this.locale}/accounting/attachment/add/${btoa(blob.signed_id)}`,
            type: "get",
            data: "",
            success: function(data) {
              accountingController.attachment_uploadingTarget.classList.add('d-none');
            },
            error: function(data) {}
          });
        }
      })
    })

    input.value = '';
  }

  deleteConfirm() {
    var that = this;
    var uuid = event.target.dataset.uuid;
    var description = event.target.dataset.description;
    bootbox.dialog({
      message: `${I18n.t('accounting.confirm.delete')}<br><br><span class="semi-bold">${description}</span>`,
      onEscape: true,
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        delete: {
          label: I18n.t('btn.delete'),
          className: "btn-danger",
          callback: function() {
            Rails.ajax({
              url: `/${that.locale}/accounting/${uuid}/delete`,
              type: "delete",
              data: "",
              success: function(data) {},
              error: function(data) {}
            });
            bootbox.hideAll();
          }
        }
      }
    });
  }

  deleteAttachment() {
    var attachment = event.target.closest("div.d-flex");
    attachment.remove();
  }

  setAccountType() {
    let ta = document.querySelectorAll('div.tgl_account');
    let ts = document.querySelectorAll('div.tgl_subaccount');
    let inp_number = document.querySelector("#account_number");

    if(event.target.dataset.accountType == 'account') {
      ts.forEach((div, i) => {
        div.classList.add('d-none');
      });
      ta.forEach((div, i) => {
        div.classList.remove('d-none');
      });

      inp_number.readonly = false;
      this.edit_account_subnumberTarget.classList.add('d-none');
    } else {
      ta.forEach((div, i) => {
        div.classList.add('d-none');
      });
      ts.forEach((div, i) => {
        div.classList.remove('d-none');
      });

      inp_number.readonly = true;
      this.edit_account_subnumberTarget.classList.remove('d-none');
    }
  }

  resetEntriesFilter() {
    Cookies.expire('accounting.entries.from');
    Cookies.expire('accounting.entries.to');
    window.location.href = `/${this.locale}/accounting/entries`
  }

  toggleAdvanced() {
    if(this.swtchAdvancedTarget.checked) {
      this.basicTarget.classList.add('d-none');
      this.basicTarget.querySelectorAll('select').forEach((select, i) => {
        select.setAttribute('disabled', true)
      });

      this.advancedTarget.classList.remove('d-none');
      this.advancedTarget.querySelectorAll('select').forEach((select, i) => {
        select.removeAttribute('disabled')
      });

      this.advUpdateTotals()
    } else {
      this.advancedTarget.classList.add('d-none');
      this.advancedTarget.querySelectorAll('select').forEach((select, i) => {
        select.setAttribute('disabled', true)
      });

      this.basicTarget.classList.remove('d-none');
      this.basicTarget.querySelectorAll('select').forEach((select, i) => {
        select.removeAttribute('disabled')
      });

      document.querySelectorAll('button[type="submit"]').forEach((btn, i) => {
        btn.removeAttribute('disabled')
      });
    }

    setTimeout(() => {
      $(".selectpicker").selectpicker('refresh');
    }, 250)
  }

  advUpdateTotals() {
    let debitTotal = 0;
    let creditTotal = 0;
    let divsD = this.advDebitAccountsTarget.querySelectorAll('input[type="number"]');
    let divsC = this.advCreditAccountsTarget.querySelectorAll('input[type="number"]');
    let btnSubmit = document.querySelectorAll('button[type="submit"]');

    for(let i=0;i<divsD.length;i++) {
      debitTotal += parseFloat(divsD[i].value);
    }
    for(let i=0;i<divsC.length;i++) {
      creditTotal += parseFloat(divsC[i].value);
    }

    debitTotal = debitTotal.toFixed(2)
    creditTotal = creditTotal.toFixed(2)

    this.advDebitTotalTarget.value = this.currenciesFormat(debitTotal, this.currencyTarget.value);
    this.advCreditTotalTarget.value = this.currenciesFormat(creditTotal, this.currencyTarget.value);

    if(debitTotal != creditTotal) {
      this.advDebitTotalTarget.classList.add('text-danger');
      this.advCreditTotalTarget.classList.add('text-danger');
      btnSubmit.forEach((btn, i) => {
        btn.setAttribute('disabled', true)
      });

    } else {
      this.advDebitTotalTarget.classList.remove('text-danger');
      this.advCreditTotalTarget.classList.remove('text-danger');
      btnSubmit.forEach((btn, i) => {
        btn.removeAttribute('disabled')
      });
    }
  }

  advAddDebitAccount() {
    let tmpl = this.advTemplatesTarget.querySelector('div');
    let div = tmpl.cloneNode(true);
    let sel_account = div.querySelector('select');
    sel_account.setAttribute('name', 'debit[][account]');
    $(sel_account).selectpicker();
    div.querySelector('input[type="number"]').setAttribute('name', 'debit[][amount]');
    this.advDebitAccountsTarget.append(div);
  }

  advAddCreditAccount() {
    let tmpl = this.advTemplatesTarget.querySelector('div');
    let div = tmpl.cloneNode(true);
    let sel_account = div.querySelector('select');
    sel_account.setAttribute('name', 'credit[][account]')
    $(sel_account).selectpicker();
    div.querySelector('input[type="number"]').setAttribute('name', 'credit[][amount]');
    this.advCreditAccountsTarget.append(div);
  }

  advDeleteAccount() {
    let div = event.target.closest('div.d-flex');
    if(div) {
      div.remove()
    }
    this.advUpdateTotals();
  }

  importCamt053() {
    let that = this;
    let input = document.createElement('input');
    input.type = "file";
    input.multiple = "multiple";
    input.accept = ".xml";

    input.addEventListener("change", () => {
      $('#loading').fadeIn(200);

      var formData = new FormData();
      formData.append("account", this.account);
      for(let i = 0; i < event.target.files.length; i++) {
        formData.append("xmlfile[]", event.target.files[i]);
      }

      Rails.ajax({
        url: `/${this.locale}/accounting/import/camt053`,
        type: "POST",
        data: formData,
        multipart: true,
        contentType: false,
        processData: false,
        method: 'POST',
        success: function(data) {},
        error: function(data) {}
      });

      input.remove();
    });

    document.querySelector('body').appendChild(input);
    input.click();
  }

  initData() {
    let entriesController = this;
    let table = $("#entries");

    $.fn.bootstrapTable.locales[this.locale]['formatShowingRows'] = function (from, to, total) {
      return I18n.t('accounting.pagination', {from: from, to: to, total: total});
    }
    $.fn.bootstrapTable.locales[this.locale]['formatRecordsPerPage'] = function (count) {
      return I18n.t('accounting.per_page', {count: count});
    }

    let url;
    if(this.page == 'account') {
      url = `/${this.locale}/account/${this.account}`;
    } else {
      url = `/${this.locale}/accounting/entries?`
      if(this.account && this.account != '') {
        url = `/${this.locale}/accounting/entries?account=${this.account}`
      }
      if(this.dtstart && this.dtend) {
        url += `&from=${this.dtstart}&&to=${this.dtend}`
      }
    }

    table.bootstrapTable({
      url: url,
      locale: this.locale,
      minHeight: '400',
      classes: 'table smaller',
      search: true,
      pagination: true,
      paginationLoop: false,
      pageSize: 100,
      pageList: [50, 100, 250, 'All'],
      searchTimeOut: 250,
      loadingFontSize: '1rem',
      sortName: 'date',
      sortOrder: 'desc'
    });

    table.on('load-success.bs.table', (data) => {
      /*
      if(this.view == 'entries') {
        entriesController.chartEntries();
      }
      */

    });
  }

  loadNextData(offset) {
    let table = $("#entries");

    let url;
    if(this.page == 'account') {
      url = `/${this.locale}/account/${this.account}?limit=500&offset=${offset}`;
    } else {
      url = `/${this.locale}/accounting/entries?limit=500&offset=${offset}`
      if(this.account) {
        url = `/${this.locale}/accounting/entries?account=${this.account}&limit=500&offset=${offset}`
      }
      if(this.dtstart && this.dtend) {
        url += `&from=${this.dtstart}&&to=${this.dtend}`
      }
    }

    let itemsController = this;
    Rails.ajax({
      url: url,
      type: "get",
      data: "",
      success: function(data) {
        table.bootstrapTable('append', data)
        if(data.length == 500) {
          //itemsController.loadNextData(offset+500);
        }
      },
      error: function(data) {
        console.log("Error loading entries");
      }
    });
  }

  initTableFunctions() {
    let that = this;
    I18n.locale = this.locale;
    let locale = this.locale;
    let account = this.account;
    let currency = this.currency;
    var accounts = this.accounts;
    let page = this.page;

    window.dateFormatter = function(date) {
      return I18n.l("date.formats.default", new Date(date));
    }

    window.addressFormatter = function(address, entry) {
      let str = `
        ${address.name}
        <div class="small text-muted text-truncate" style="max-width:350px;">
          ${[address.addressline1, address.addressline2, address.street, address.zip, address.location].filter(element => {return element !== '';}).join(', ')}
        </div>
      `
      return str;
    }

    window.referenceFormatter = function(reference, entry) {
      let str = reference;
      if(entry.data && entry.data.invoice) {
        str += `<br><span class="small">${I18n.t('document.invoice')}:&nbsp;<a href="/${locale}/document/${entry.data.invoice.uuid}">${entry.data.invoice.reference}</a></span>`
      } else if(entry.data && entry.data.order) {
        str += `<br><span class="small">${I18n.t('order.order')}:&nbsp;<a href="/${locale}/order/${entry.data.order.uuid}">${entry.data.order.reference}</a></span>`
      } else if(entry.data && entry.data.purchase) {
        str = `<br><span class="small">${I18n.t('purchase.purchase')}:&nbsp;<a href="/${locale}/purchase/${entry.data.purchase.uuid}">${entry.data.purchase.reference}</a></span>`
      }
      return str;
    }

    window.expenseFormatter = function(amount, entry) {
      if(entry.kind == 'expense') {
        let str = `- ${amount.toFixed(2)} ${this.currenciesAbbr(entry.currency)}`
        if(entry.data.currencies) {
          str += `
            <div class="small">
              ${entry.data.currencies['CHF'].total.toFixed(2)}
              ${this.currenciesAbbr(Object.keys(entry.data.currencies)[0])}
            </div>
          `;
        }
        return str;
      } else {
        return '';
      }
    }

    window.debitFormatter = function(debit, entry) {
      let str = '';
      if(entry.debit_accounts) {
        entry.debit_accounts.forEach((debit, i) => {
          str += `<div class="d-flex justify-content-between"><span>${debit.number}${debit.subnumber ? debit.subnumber : ''} ${debit.name}</span><span class="semi-bold ms-2">${debit.amount.toFixed(2)}</span></div>`
        });
      }
      return str;
    }

    window.creditFormatter = function(credit, entry) {
      let str = '';
      if(entry.credit_accounts) {
        entry.credit_accounts.forEach((credit, i) => {
          str += `<div class="d-flex justify-content-between"><span>${credit.number}${credit.subnumber ? credit.subnumber : ''} ${credit.name}</span><span class="semi-bold ms-2">${credit.amount.toFixed(2)}</span></div>`
        });
      }
      return str;
    }

    window.currencyFormatter = function(currency, entry) {
      return that.currenciesAbbr(currency);
    }

    window.amountFormatter = function(gross, entry) {
      if(gross) {
        return gross.toFixed(2);
      } else {
        return '';
      }
    }

    window.accountAmountFormatter = function(amount, entry) {
      if(entry.kind == 'income') {
        return `${that.currenciesFormat(amount.toFixed(2), currency)}`;
      } else {
        return `${that.currenciesFormat((0-amount).toFixed(2), currency)}`;
      }
    }

    window.accountBalanceFormatter = function(balance, entry) {
      if(entry.currency) {
        currency = entry.currency;
      }
      let txtClass = '';
      if(balance < 0) {
        txtClass = 'text-danger';
      }
      return `<span class="${txtClass}">${that.currenciesFormat(balance.toFixed(2), currency)}</span>`;
    }

    window.accountNameFormatter = function(address, entry) {
      let str = '';
      if(entry.kind == 'income') {
        if(entry.data.debitor && entry.data.debitor.name) {
          str += entry.data.debitor.name
          if(entry.data.reference) {
            str += `<div class="small text-muted">${entry.data.reference}</small>`;
          }
        } else {
          str += entry.data.reference;
        }
      } else {
        if(entry.data.creditor && entry.data.creditor.name) {
          str += entry.data.creditor.name;
          if(entry.data.reference) {
            str += `<div class="small text-muted">${entry.data.reference}</small>`;
          }
        } else {
          str += entry.data.reference;
        }
      }
      /*
      str += `
        <span class="small text-muted ms-2">
          ${[address.addressline1, address.addressline2, address.street, address.zip, address.location].filter(element => {return element !== '';}).join(', ')}
        </span>
      `;
      */
      if(entry.category && entry.category_name != 'others') {
        str += `
          <span class="float-end">
            <span class="badge bg-light text-dark">
        `;
        if(entry.category_subscription) {
          str += entry.category_name;
        } else {
          str += I18n.t(`accounting.category.${entry.category_name}`);
        }
        str += `
            </span>
          </span>
        `;
      }
      return str;
    }

    window.accountReferenceFormatter = function(data, entry) {
      let str = '';

      if(entry.data && entry.data.invoice) {
        str += `<a href="/${locale}/document/${entry.data.invoice.uuid}">${entry.data.invoice.reference}</a>`;
      } else if(entry.data && entry.data.invoices) {
        entry.data.invoices.forEach((invoice, i) => {
          str += `<a href="/${locale}/document/${invoice.uuid}">${invoice.reference}</a><br>`;
        });
      } else if(entry.data && entry.data.order) {
        str += `<a href="/${locale}/order/${entry.data.order.uuid}">${entry.data.order.reference}</a>`;
      } else if(entry.data && entry.data.purchase) {
        str += `<a href="/${locale}/purchase/${entry.data.purchase.uuid}">${entry.data.purchase.reference}</a>`;
      }

      if(entry.kind == 'income' && !entry.data.invoice && !entry.data.order && !entry.data.purchase) {
        if(entry.data.possible_matches) {
          if(entry.data.possible_matches.count > 0) {
            str += `<a class="badge bg-secondary" data-remote="true" href="/${locale}/accounting/reconciliation/${entry.uuid}">${I18n.t('accounting.possible_matches', {count: entry.data.possible_matches.count})}</a>`
          }
        }
      }

      return str;
    }

    window.actionFormatter = function(actions, entry) {
      let str = '';
      if(entry.attachments > 0) {
        str += `<i class="fas fa-paperclip me-2 text-muted" title="${I18n.t('accounting.attachments', {count: entry.attachments})}"></i>`
      }
      if(account && page == 'account') {
        str += `
          <a class="btn btn-sm btn-outline-secondary" href="/${locale}/accounting/entry/${entry.uuid}/${account}"><i class="fas fa-info-circle"></i></a>
          <a class="btn btn-sm btn-outline-secondary" href="/${locale}/accounting/bankentry/${account}/${entry.uuid}/edit"><i class="fas fa-edit"></i></a>
        `;
      } else {
        str += `
          <a class="btn btn-sm btn-outline-secondary" href="/${locale}/accounting/entry/${entry.uuid}"><i class="fas fa-info-circle"></i></a>
          <a class="btn btn-sm btn-outline-secondary" href="/${locale}/accounting/entry/${entry.uuid}/edit"><i class="fas fa-edit"></i></a>
        `;
      }
      return str;
    }
  }

}
